import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import './SelectComponent.css';

const SelectComponent = ({ label, items, selectedItem, onItemSelected, disabled = false }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef(null);

    const handleToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleSelect = (item) => {
        if (!disabled) {
            onItemSelected(item);
            setIsOpen(false);
        }
    };

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`select-wrapper ${disabled ? 'disabled' : ''}`}>
            {label ? <label className="label">{label}</label> : ""}
            <div 
                className={`select ${isOpen ? 'opened' : ''}`} 
                onClick={handleToggle} 
                ref={selectRef}
            >
                <div className="select__header">
                    <div className="select__title">{selectedItem ? selectedItem.name : t("name-select-option")}</div>
                    <img className="select__arrow" src="/select-arrow.svg" alt="dropdown" />
                </div>
                {isOpen && (
                    <div className="select__content">
                        <ul className="sub-list">
                            {items.map((item, index) => (
                                <li
                                    key={index}
                                    className={`sub-list__item ${selectedItem === item ? 'selected' : ''}`}
                                    onClick={() => handleSelect(item)}
                                >
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectComponent;
