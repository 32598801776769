import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../components/BreadcrumbsComponent";
import CodeWithTabs from '../../components/CodeWithTabs';

import style from '../../styles/Support.module.scss';

export const SupportWhitelistingIpsPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("page-title-guide-whitelisting-ips")}</title>
                <meta name="description" content={t("page-description-guide-whitelisting-ips")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-guide-whitelisting-ips")} />
                <meta property="og:description" content={t("page-description-guide-whitelisting-ips")} />
            </Helmet>
            <main className={style['support-main']}>
                <div className={style['container']}>
                    <Breadcrumbs />
                    <div className={style['support-article']}>
                        <div className={style['support-article__header']}>
                            <div className={style['container']}>
                                <h1 className={style['support-article__title']}>{t('name-pool-1-whitelist-title')}</h1>
                            </div>
                        </div>
                        <div className={style['support-article__content']}>
                            <h3>{t('ipSkippingFeature')}</h3>
                            <p>
                                {t('name-pool-1-whitelist-description')}
                            </p>
                            <h4>{t('name-pool-1-whitelist-benefits-title')}</h4>
                            <p>{t('name-pool-1-whitelist-benefits-description')}</p>
                            <ul>
                                <li>{t('name-pool-1-whitelist-benefit-1')}</li>
                                <li>{t('name-pool-1-whitelist-benefit-2')}</li>
                                <li>{t('name-pool-1-whitelist-benefit-3')}</li>
                                <li>{t('name-pool-1-whitelist-benefit-4')}</li>
                            </ul>
                            <img src="/whitelisting-ips-readme-1.png" alt={t('createNewList')} />
                            <p>
                                {t('name-pool-1-whitelist-step-1')}
                            </p>
                            <p>{t('name-pool-1-whitelist-step-2')}</p>
                            <img src="/whitelisting-ips-readme-2.png" alt={t('createNewList')} />
                            <p>
                                {t('name-pool-1-whitelist-step-3')}
                            </p>
                            <p>{t('name-pool-1-whitelist-step-4')}</p>
                            <img src="/whitelisting-ips-readme-3.png" alt={t('createNewList')} />
                            <p>{t('name-pool-1-whitelist-step-5')}</p>
                        </div>
                        <div className={style['support-article__footer']}>
                            <div className={style['container']}></div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
