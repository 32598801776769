import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import style from '../../styles/Support.module.scss';

export const SupportCataloguePage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("page-title-support")}</title>
                <meta name="description" content={t("page-description-support")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-support")} />
                <meta property="og:description" content={t("page-description-support")} />
            </Helmet>
            <main className={style['support-main']}>
                <div className={style['support-catalogue']}>
                    <div className={style['container']}>
                        <div className={style['support-card']}>
                            <div className={style['support-card__header']}>
                                <div className={style['support-card__icon']}>
                                    <img src="/advanced.svg" alt=""></img>
                                </div>
                                <h3 className={style['support-card__title']}>{t("name-instruction-for-pool-1-proxies")}</h3>
                            </div>
                            <div className={style['support-card__content']}>
                                <div className={style['support-card__nav']}>
                                    <Link className={style['support-card__link']} to={"/support/pool-1-terms"}>{t("name-pool-1-terms")}</Link>
                                    <Link className={style['support-card__link']} to={"/support/ip-skipping"}>{t("name-ip-skipping")}</Link>
                                    <Link className={style['support-card__link']} to={"/support/whitelisting-ips"}>{t("name-whitelisting-ips")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
