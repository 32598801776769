import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ProductTable.css';



const ProductTable = ({ columns, products, onItemSelected }) => {
    const { t } = useTranslation();
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleRowClick = (product) => {
        setSelectedProduct(product);
        onItemSelected(product)
    };

    return (
        <table className="product-table">
            <thead>
                <tr>
                    {
                        columns ? columns.map((item, index) => (
                            <th key={index}>{t(item.name)}</th>
                        )) : ''
                    }
                </tr>
            </thead>
            <tbody>
                {products
                    .sort((a, b) => a.price - b.price)
                    .map((item) => (
                        <tr
                            key={item.id}
                            className={selectedProduct && selectedProduct.id === item.id ? 'selected' : ''}
                            onClick={() => handleRowClick(item)}
                        >
                            {item?.data?.characters ? (
                                item.data.characters.map((character, index) => (
                                    <td key={index}>
                                        {character}
                                    </td>
                                ))
                            ) : (
                                <td style={{ textAlign: 'center' }} colSpan={3}>{t('name-empty')}</td>
                            )}
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};

export default ProductTable;
