import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import registerUser from "../api/users/registerUser.js";
import { authUser } from '../store/userSlice';

import processErrors from '../utils/processErrors';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const RegisterPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const auth = useSelector((state) => state.user);

    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        if (auth && auth?.status === 'succeeded' && auth?.token) {
            toast.success(t('notification-login-success'));
            setTimeout(() => navigate('/account'), 1000);
        } else if (auth && auth.status === 'failed') {
            const errorMessage = processErrors(auth.error);

            toast.error(t(errorMessage));
        }
    }, [auth, navigate, t]);

    const handleRegister = async (event) => {
        event.preventDefault();
        try {
            if (password.length < 6) {
                throw new Error('error-password-short');
            } else if (password !== confirmPassword) {
                throw new Error('error-passwords-not-match');
            }

            toast.info(t('notification-request-await'));
            const response = await registerUser(email, username, password);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            toast.success(t('notification-register-success'));
            dispatch(authUser({ email, password }));
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    useEffect(() => {
        if (auth && auth?.isAuthenticated) {
            toast.success(t('notification-login-success'));
            setTimeout(() => navigate('/account'), 1000);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{t("page-title-register")}</title>
                <meta name="description" content={t("page-description-register")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-register")} />
                <meta property="og:description" content={t("page-description-register")} />
            </Helmet>
            <div className="auth-page">
                <main className="auth-main">
                    <div className="auth-main__side">
                        <div className="container">
                            <h1 className="auth-h1">{t('auth-welcome')}</h1>
                            <p className="auth-p">{t('auth-access-hint')}</p>
                            <Link className="primary-btn" to={"/login"}>{t('button-login')}</Link>
                        </div>
                    </div>
                    <div className="auth-main__side">
                        <Link className="logo" to='/'>
                            <img src="/logo.svg" alt="" />
                        </Link>
                        <p className="auth-p" style={{marginBottom: "43px"}}>{t('name-registration')}</p>
                        <form onSubmit={handleRegister}>
                            <div className="input-wrapper">
                                <div className="input-wrapper__icon">
                                    <img style={{filter: "grayscale(1)"}} src="/user.svg" alt="" />
                                </div>
                                <input
                                    className="input"
                                    type="text"
                                    id="username"
                                    value={username}
                                    placeholder={t("name-username")}
                                    onChange={(e) => setUsername(e.target.value)}
                                    minLength={4}
                                    required
                                />
                            </div>
                            <div className="input-wrapper">
                                <div className="input-wrapper__icon">
                                    <img src="/mail.svg" alt="" />
                                </div>
                                <input
                                    className="input"
                                    type="text"
                                    id="email"
                                    value={email}
                                    placeholder={t("name-email")}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-wrapper">
                                <div className="input-wrapper__icon">
                                    <img src="/lock.svg" alt="" />
                                </div>
                                <input
                                    className="input"
                                    type="password"
                                    id="password"
                                    placeholder={t("name-password")}
                                    value={password}
                                    minLength={6}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-wrapper">
                                <div className="input-wrapper__icon">
                                    <img src="/lock.svg" alt="" />
                                </div>
                                <input
                                    className="input"
                                    type="password"
                                    id="confirm-password"
                                    placeholder={t("name-retype-password")}
                                    value={confirmPassword}
                                    minLength={6}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button className="primary-btn" type="submit">{t('button-create-account')}</button>
                            <Link className="auth-link" to={"/forgot-password"} style={{display: "block", margin: "43px auto"}}>{t('name-forgot-name-or-password')}</Link>
                        </form>
                    </div>
                </main>
                <ToastContainer theme="colored" />
            </div>
        </>
    );
};
