import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import './DropdownButton.css';

const DropdownButton = ({ title, options }) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleButtonClick = () => {
        setOpen(!open);
    };

    const handleOptionClick = (option) => {
        option.action();
        setOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`dropdown-button ${open ? 'opened' : ''}`} ref={dropdownRef}>
            <button className='primary-btn' onClick={handleButtonClick}>
                {title ? title : t("button-actions")}
            </button>
            {open && (
                <ul className="dropdown-menu">
                    {options.map((option, index) => (
                        <li key={index} onClick={() => handleOptionClick(option)}>
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DropdownButton;
