import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("page-title-not-found")}</title>
                <meta name="description" content={t("page-description-not-found")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-not-found")} />
                <meta property="og:description" content={t("page-description-not-found")} />
            </Helmet>
            <div className="not-found-page">
                <div className="container">
                    <div className="not-found-wrapper">
                        <h1>{t('pageNotFound.title')}</h1>
                        <p>{t('pageNotFound.message')}</p>
                    </div>
                    <Link className="not-found-link" to="/">
                        {t('pageNotFound.homeLink')}
                    </Link>
                </div>
            </div>
        </>
    );
};
