const getAvailableOrderTypes = async (token) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/user/available_order_types`;

    try {
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default getAvailableOrderTypes;
