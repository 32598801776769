const addIPSkippingList = async (parameters, token) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/residential_proxy/pool-2/ips-skipping/add`;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(parameters)
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default addIPSkippingList;
