import React from 'react';
import './TelegramButton.css';

const TelegramButton = () => {
    const handleClick = () => {
        window.open('https://t.me/psbhosting', '_blank');
    };

    return (
        <button className="telegram-btn" onClick={handleClick}>
            <img src='/telegram-svgrepo-com.svg' alt='telegram'/>
        </button>
    );
};

export default TelegramButton;
