const forgotPassword = async (email) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/user/forgot-password`;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });

        return response;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default forgotPassword;
