import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BreadcrumbsComponent.css';
import { useTranslation } from "react-i18next";

const Breadcrumbs = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/">{t('home')}</Link>
                </li>
                {pathnames.map((value, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    return (
                        <li
                            key={to}
                            className={`breadcrumb-item ${index === pathnames.length - 1 ? 'active' : ''}`}
                        >
                            {index === pathnames.length - 1 ? (
                                t(value)
                            ) : (
                                <Link to={to}>{t(value)}</Link>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
