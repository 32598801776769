import React, { useState, useEffect } from "react";
import useDebouncedEffect from "../../../components/useDebouncedEffect.js";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

import DropdownButton from '../../../components/DropdownButton';

import getData from "../../../api/residential_proxy_pool_1/getData.js";
import generateProxyLists from "../../../api/residential_proxy_pool_1/generateProxyLists.js";
import getWhitelistEntry from "../../../api/residential_proxy_pool_1/getWhitelistEntry.js";
import addWhitelistEntry from "../../../api/residential_proxy_pool_1/addWhitelistEntry.js";
import removeWhitelistEntry from "../../../api/residential_proxy_pool_1/removeWhitelistEntry.js";

import Preloader from "../../../components/Preloader";

import { toast } from 'react-toastify';

import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    Area,
} from "recharts";

import Popup from "../../../components/Popup";
import HintComponent from "../../../components/HintComponent";
import SelectComponent from "../../../components/SelectComponent";
import SelectSearchComponent from "../../../components/SelectSearchComponent";
import InputTagsComponent from "../../../components/InputTagsComponent";

import processErrors from '../../../utils/processErrors';
import formatDate from "../../../utils/formatDate";

export const OrderResidentialProxyPool1Page = () => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.user);
    const [popupOpen, setPopupOpen] = useState(false);

    const [currentTab, setCurrentTab] = useState('tab-configuration');

    const [residentialProxyData, setResidentialProxyData] = useState();
    const [trafficStats, setTrafficStats] = useState([]);
    const [selectedTrafficStatsTab, setSelectedTrafficStatsTab] = useState("resource-using");
    const [selectedTrafficUnit, setSelectedTrafficUnit] = useState({
        "name": "GB",
        "value": "gb",
    });
    const available_traffic_units = [
        {
            "name": "MB",
            "value": "mb",
        },
        {
            "name": "GB",
            "value": "gb",
        }
    ]
    const [trafficStatsStartDate, setTrafficStatsStartDate] = useState('');
    const [trafficStatsEndDate, setTrafficStatsEndDate] = useState('');

    const [availableCountries, setAvailableCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedExcludeCountries, setSelectedExcludeCountries] = useState([]);
    const [selectedProtocol, setSelectedProtocol] = useState();
    const [selectedRotation, setSelectedRotation] = useState();
    const [selectedHostname, setSelectedHostname] = useState();
    const [selectedUsername, setSelectedUsername] = useState();
    const [selectedPassword, setSelectedPassword] = useState();
    const [selectedDynamicFilter, setSelectedDynamicFilter] = useState(false);
    const [selectedASN, setSelectedASN] = useState("");
    const [selectedRotationInterval, setSelectedRotationInterval] = useState();
    const [selectedFormat, setSelectedFormat] = useState();
    const [selectedQuantity, setSelectedQuantity] = useState(10);

    const [newWhitelistEntryIP, setNewWhitelistEntryIP] = useState("");

    const [proxyLists, setProxyLists] = useState([]);
    const [availableProtocols, setAvailableProtocols] = useState([]);
    const [availableHostnames, setAvailableHostnames] = useState([]);
    const [availableWhitelist, setAvailableWhitelist] = useState([]);

    const [isActiveButtonNewWhitelistEntry, setIsActiveButtonNewWhitelistEntry] = useState(true);

    const convertToMB = (valueInGB) => valueInGB * 1024;
    const filterByDateRange = (data, startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return data.filter(item => {
            const itemDate = new Date(item.createdAt);
            return itemDate >= start && itemDate <= end;
        });
    };

    useEffect(() => {
        if (residentialProxyData?.traffic_stats && residentialProxyData?.traffic_stats?.length > 0) {
            const trafficStatsDateFiltered = filterByDateRange(residentialProxyData?.traffic_stats, trafficStatsStartDate, trafficStatsEndDate);
            const resourceUsage = trafficStatsDateFiltered.map((item, index, array) => {
                const currentUnit = selectedTrafficUnit.value;

                const currentTrafficBalance = currentUnit === 'mb' ? convertToMB(item.traffic_balance) : item.traffic_balance;

                if (index === 0) {
                    return {
                        ...item,
                        traffic_balance: 0,
                        createdAt: formatDate(item?.createdAt, "date-without-year")
                    };
                } else {
                    const previousItem = array[index - 1];
                    const previousTrafficBalance = currentUnit === 'mb' ? convertToMB(previousItem.traffic_balance) : previousItem.traffic_balance;
                    return {
                        ...item,
                        traffic_balance: Math.abs((previousTrafficBalance - currentTrafficBalance).toFixed(2)),
                        createdAt: formatDate(item?.createdAt, "date-without-year")
                    };
                }
            });

            if (selectedTrafficStatsTab === "resource-using") {
                setTrafficStats(resourceUsage);
            } else if (selectedTrafficStatsTab === "resource-left") {
                const formatted_traffic_stats = trafficStatsDateFiltered?.map((item) => {
                    const currentTrafficBalance = selectedTrafficUnit === 'mb' ? convertToMB(item.traffic_balance) : item.traffic_balance;
                    return {
                        traffic_balance: currentTrafficBalance,
                        createdAt: formatDate(item?.createdAt, "date-without-year")
                    };
                }) || [];
                setTrafficStats(formatted_traffic_stats);
            }
        }
    }, [residentialProxyData, selectedTrafficStatsTab, selectedTrafficUnit, trafficStatsStartDate, trafficStatsEndDate]);

    const downloadFile = (data, fileName, fileType) => {
        const blob = new Blob([data], { type: fileType });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);

        toast.success(t('name-download-success'));
    };

    const downloadCSV = () => {
        const csvData = proxyLists.map(item => item).join('\n');
        downloadFile(csvData, 'proxyList.csv', 'text/csv');
    };

    const downloadTXT = () => {
        const txtData = proxyLists.join('\n');
        downloadFile(txtData, 'proxyList.txt', 'text/plain');
    };

    const availableProxylistDownloadOptions = [
        { label: '.CSV', action: downloadCSV },
        { label: '.TXT', action: downloadTXT },
    ];


    const fetchResidentialProxyData = async () => {
        const data = await getData(user.token);
        if (data?.message === 'Forbidden') {
            window.location.href = '/login'
        }
        setResidentialProxyData(data);

        if (data.available_hostnames && data.available_hostnames.length > 0) {
            setAvailableHostnames(data.available_hostnames);
            setSelectedHostname(data.available_hostnames[0]);
        }

        if (data.available_protocols && data.available_protocols.length > 0) {
            setAvailableProtocols(data.available_protocols);
            setSelectedProtocol(data.available_protocols[0]);
        }

        if (data.available_countries && data.available_countries.length > 0) {
            const formatted_countries = data.available_countries.map((item) => ({ name: item?.country_name, value: item?.country_code }))
            setAvailableCountries(formatted_countries)
        }

        if (data.available_usernames && data.available_usernames.length > 0) {
            setSelectedUsername(data.available_usernames[0]);
        }

        if (data.available_passwords && data.available_passwords.length > 0) {
            setSelectedPassword(data.available_passwords[0]);
        }

        if (data.available_rotations && data.available_rotations.length > 0) {
            setSelectedRotation(data.available_rotations[0]);
        }

        if (data.available_formats && data.available_formats.length > 0) {
            setSelectedFormat(data.available_formats[0]);
        }

        if (data?.traffic_stats && data?.traffic_stats?.length > 0) {
            const formatted_traffic_stats = data?.traffic_stats?.map((item) => ({
                traffic_balance: item?.traffic_balance,
                createdAt: formatDate(item?.createdAt, "date-without-year")
            })) || [];
            setTrafficStats(formatted_traffic_stats)

            const formatDateForInput = (dateString) => {
                const date = new Date(dateString);
                return date.toISOString().split('T')[0];
            };

            setTrafficStatsStartDate(formatDateForInput(data?.traffic_stats[0]?.createdAt))
            setTrafficStatsEndDate(formatDateForInput(data?.traffic_stats[data?.traffic_stats.length - 1]?.createdAt))
        }

        if (data?.whitelist && data?.whitelist?.length > 0) {
            setAvailableWhitelist(data?.whitelist)
        }
    };

    const fetchGenerateProxyLists = async () => {
        try {
            if (!user.token) {
                window.location.href = '/login';
                return;
            }

            var queries = {
                protocol: selectedProtocol.value,
                format: selectedFormat.value,
                hostname: selectedHostname.value,
                rotation: selectedRotation.value,
                proxy_count: selectedQuantity,
                anonymous: selectedDynamicFilter ? 1 : 0,
            }

            if (selectedASN) {
                queries.exclude_asns = selectedASN;
            }

            if (selectedCountries && selectedCountries?.length > 0) {
                queries.location = selectedCountries.map(item => item.value).join(',');
            }

            if (selectedExcludeCountries && selectedExcludeCountries?.length > 0) {
                queries.exclude_countries = selectedExcludeCountries.map(item => item.value).join(',');
            }

            if (selectedRotationInterval) {
                queries.sessionttl = selectedRotationInterval;
            }

            const data = await generateProxyLists(queries, user.token);

            if (data?.message === 'Forbidden') {
                window.location.href = '/login';
                return;
            }

            if (data?.message === 'Server error') {
                console.error('Server error');
                return;
            }

            setProxyLists(data);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleWhitelistEntryAdd = async () => {
        try {
            setIsActiveButtonNewWhitelistEntry(false);
            const response = await addWhitelistEntry({ ip: newWhitelistEntryIP }, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                setIsActiveButtonNewWhitelistEntry(true);
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            setIsActiveButtonNewWhitelistEntry(true);
            setPopupOpen(false);
            setNewWhitelistEntryIP('');
            toast.success(t('name-whitelist-add-success'))

            const whitelist_data = await getWhitelistEntry(user.token);
            setAvailableWhitelist(whitelist_data)
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    const handleWhitelistEntryRemove = async (ip_address) => {
        const data = await removeWhitelistEntry({
            ip: ip_address
        }, user.token);
        if (data?.message === 'Forbidden') {
            window.location.href = '/login'
        }
        if (data?.message === 'Server error') {
            toast.error(t('name-whitelist-remove-error'))
            return
        }
        toast.success(t('name-whitelist-remove-success'))

        const whitelist_data = await getWhitelistEntry(user.token);
        setAvailableWhitelist(whitelist_data)
    };

    useEffect(() => {
        fetchResidentialProxyData();
    }, []);

    useDebouncedEffect(() => {
        if (selectedFormat && selectedHostname && selectedRotation && selectedProtocol && selectedCountries && selectedQuantity) {
            fetchGenerateProxyLists();
        }
    }, [
        selectedFormat,
        selectedHostname,
        selectedRotation,
        selectedCountries,
        selectedProtocol,
        selectedQuantity,
        selectedASN,
        selectedDynamicFilter,
        selectedRotationInterval,
        selectedExcludeCountries
    ], 1000);

    useEffect(() => {
        setSelectedRotationInterval('');
    }, [selectedRotation]);

    const handleCopy = () => {
        const textareaValue = proxyLists.join('\n');
        navigator.clipboard.writeText(textareaValue).then(() => {
            toast.success(t('name-copy-success'));
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };


    const CustomTooltip2 = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>
                    <div className="graph-tooltip">
                        <div className="graph-tooltip__value">{payload[0].value}</div>
                        <div className="graph-tooltip__name">{selectedTrafficUnit?.name}</div>
                    </div>
                </>
            );
        }
    };

    const handleSelectedASNChange = (newValue) => {
        setSelectedASN(newValue);
    };

    return (
        <>
            <Helmet>
                <title>{t("page-title-account")}</title>
            </Helmet>
            {
                !residentialProxyData ? <Preloader></Preloader>
                    :
                    <>
                        <div className="tabs-wrapper">
                            <div
                                className={"tab-item " + (currentTab === 'tab-configuration' ? 'selected' : "")}
                                onClick={() => setCurrentTab('tab-configuration')}
                            >
                                {t('name-configuration')}
                            </div>
                            <div
                                className={"tab-item " + (currentTab === 'tab-whitelist' ? 'selected' : "")}
                                onClick={() => setCurrentTab('tab-whitelist')}
                            >
                                {t('name-whitelist')}
                            </div>
                        </div>
                        {
                            currentTab === 'tab-configuration' &&
                                residentialProxyData &&
                                residentialProxyData?.available_countries &&
                                residentialProxyData?.available_rotations &&
                                residentialProxyData?.traffic_available &&
                                residentialProxyData?.available_formats ?
                                <div className="proxylist-wrapper">
                                    <div className="grid-wrapper">
                                        {
                                            residentialProxyData &&
                                            residentialProxyData?.available_countries &&
                                            <div className="form">
                                                <div className="form-header">
                                                    <h2 className="text-h2">{t('name-proxy-access')}</h2>
                                                    <div className="checkbox-wrapper">
                                                        <input className="switch" type="checkbox" onChange={(e) => (setSelectedDynamicFilter(!selectedDynamicFilter))} name="" id="" />
                                                        <label className="label" htmlFor="">
                                                            <HintComponent title={t("name-anonymous-filter")} content={t("hint-anonymous-filter")} />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <SelectSearchComponent label={t('name-country-or-region')} multiple={true} items={availableCountries} selectedItems={selectedCountries} onItemsSelected={setSelectedCountries}></SelectSearchComponent>
                                                    <div className="select-wrapper">
                                                        <label className="label" htmlFor="">
                                                            <HintComponent title={t('name-proxy-host-name')} content={t("hint-proxy-host-name-pool-1")} />
                                                        </label>
                                                        <SelectComponent items={availableHostnames} selectedItem={selectedHostname} onItemSelected={setSelectedHostname}></SelectComponent>
                                                    </div>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <SelectSearchComponent label={t('name-exclude-country-or-region')} multiple={true} items={availableCountries} selectedItems={selectedExcludeCountries} onItemsSelected={setSelectedExcludeCountries}></SelectSearchComponent>
                                                    <SelectComponent label={t('name-protocol')} items={availableProtocols} selectedItem={selectedProtocol} onItemSelected={setSelectedProtocol}></SelectComponent>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <SelectComponent label={t('name-username')} items={residentialProxyData.available_usernames} selectedItem={selectedUsername} onItemSelected={setSelectedUsername}></SelectComponent>
                                                    <SelectComponent label={t('name-password')} items={residentialProxyData.available_passwords} selectedItem={selectedPassword} onItemSelected={setSelectedPassword}></SelectComponent>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <SelectComponent label={t('name-rotation')} items={residentialProxyData.available_rotations} selectedItem={selectedRotation} onItemSelected={setSelectedRotation}></SelectComponent>
                                                    <div className="form-section">
                                                        <div className="form-section__header">
                                                            <h3 className="text-h3">{t("name-rotation-interval")}</h3>
                                                            <HintComponent content={t("hint-rotation-interval")} />
                                                        </div>
                                                        <div className="form-section__content">
                                                            <div className="input-wrapper_fade">
                                                                <input className="input" disabled={selectedRotation?.value !== 'sticky'} value={selectedRotationInterval} placeholder={t("name-rotation-interval-placeholder")} onChange={(e) => (setSelectedRotationInterval(e.target.value))} min={0} max={120} type="number" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-section">
                                                    <div className="form-section__header">
                                                        <h3 className="text-h3">{t("name-exclude-asn")}</h3>
                                                        <HintComponent content={t("hint-exclude-asn")} />
                                                    </div>
                                                    <div className="form-section__content">
                                                        <InputTagsComponent value={selectedASN} onChange={handleSelectedASNChange}></InputTagsComponent>
                                                    </div>
                                                </div>
                                                <label className="label">{t('name-curl-example')}</label>
                                                <div className="input-wrapper_fade">
                                                    <input className="input" type="text" value={`$ curl -v -x ${selectedProtocol?.value}://${selectedUsername?.value}:${selectedPassword?.value}@${selectedHostname?.value}:${823} -L https://ipv4.icanhazip.com`} disabled />
                                                </div>
                                            </div>
                                        }
                                        <div className="form">
                                            <div className="form-header">
                                                <h2 className="text-h2">{t('name-formatted-proxy-list')}</h2>
                                            </div>
                                            <textarea
                                                className="textarea"
                                                value={proxyLists.join('\n')}
                                                name=""
                                                id=""
                                            ></textarea>
                                            <div className="grid-wrapper" style={{ alignItems: "center" }}>
                                                <div className="primary-btn" onClick={handleCopy}>{t('name-copy')}</div>
                                                <DropdownButton title={t("button-downloads-list")} options={availableProxylistDownloadOptions} />
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-format")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <SelectComponent items={residentialProxyData.available_formats} selectedItem={selectedFormat} onItemSelected={setSelectedFormat}></SelectComponent>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-count")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper_fade">
                                                        <input className="input" value={selectedQuantity} onChange={(e) => (setSelectedQuantity(e.target.value))} min={1} max={10000} type="number" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        residentialProxyData?.traffic_available ?
                                            <div className="form">
                                                <div className="form-header">
                                                    <h2 className="text-h2">{t('name-available-traffic')}</h2>
                                                </div>
                                                <div className="input-wrapper_fade">
                                                    <input className="input traffic-left" style={{ textAlign: "center", opacity: 1, cursor: "auto" }} value={Number(residentialProxyData.traffic_available).toFixed(3) + ' GB'} disabled={true} type="text" />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        trafficStats && trafficStats?.length > 0 ?
                                            <div className="form">
                                                <div className="form-header">
                                                    <div>
                                                        <h2 className="text-h2">{t('name-statistics')}</h2>
                                                        <div className="graph-tabs">
                                                            <div
                                                                className={`graph-tabs__item ${selectedTrafficStatsTab === "resource-using" ? "selected" : ""}`}
                                                                onClick={() => setSelectedTrafficStatsTab("resource-using")}
                                                            >
                                                                {t('name-resource-using')}
                                                            </div>
                                                            <div
                                                                className={`graph-tabs__item ${selectedTrafficStatsTab === "resource-left" ? "selected" : ""}`}
                                                                onClick={() => setSelectedTrafficStatsTab("resource-left")}
                                                            >
                                                                {t('name-value-resources-left')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <SelectComponent items={available_traffic_units} selectedItem={selectedTrafficUnit} onItemSelected={setSelectedTrafficUnit}></SelectComponent>
                                                        <div className="input-wrapper_fade date-wrapper">
                                                            <input className="input" type="date" id="start-date" value={trafficStatsStartDate} onChange={(e) => setTrafficStatsStartDate(e.target.value)} />
                                                            <input className="input" type="date" id="end-date" value={trafficStatsEndDate} onChange={(e) => setTrafficStatsEndDate(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <ResponsiveContainer width="100%" height={250}>
                                                    <AreaChart
                                                        data={trafficStats}
                                                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="colorDifficulty" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="5%" stopColor="#A038AA" stopOpacity={0.15} />
                                                                <stop offset="95%" stopColor="#A038AA" stopOpacity={0} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid />
                                                        <XAxis dataKey="createdAt" label={{ value: '', position: 'insideBottomRight', offset: 0 }} />
                                                        <YAxis dataKey="traffic_balance" label={{ value: selectedTrafficUnit?.name, angle: -90, position: 'insideLeft' }} domain={[0, (Math.max(...trafficStats.map(item => item.traffic_balance)) + (Math.max(...trafficStats.map(item => item.traffic_balance)) * 0.1))]} />
                                                        <Tooltip content={<CustomTooltip2 />} />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="traffic_balance"
                                                            stroke="#4E9AA5"
                                                            fillOpacity={1}
                                                            strokeWidth={2}
                                                            fill="transparent"
                                                        />
                                                    </AreaChart>
                                                </ResponsiveContainer>
                                            </div>
                                            : ""
                                    }
                                </div>
                                : ''
                        }
                        {
                            currentTab === 'tab-whitelist' ?
                                <>
                                    <div className="form">
                                        <div>
                                            <h2 className="text-h2">{t("name-ip-adresses-in-whitelist")}</h2>
                                            <table className="table_fade">
                                                <thead>
                                                    <th>{t("name-ip")}</th>
                                                    <th>{t("name-actions")}</th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        availableWhitelist && availableWhitelist?.length > 0 ?
                                                            availableWhitelist.map((item) => (
                                                                <tr>
                                                                    <td data-label={t("name-ip")}>{item}</td>
                                                                    <td data-label={t("name-actions")}>
                                                                        <button className="delete-button" onClick={(e) => (handleWhitelistEntryRemove(item))}>{t("button-delete")}</button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr>
                                                                <td colSpan="6" style={{ textAlign: "center", color: "rgb(133, 133, 133)" }}>{t("name-no-data")}</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="primary-btn" onClick={(e) => (setPopupOpen(true))}>{t("button-add")}</div>
                                    </div>
                                    <Popup title={t("popup-title-whitelist-entry-create")} trigger={popupOpen} setTrigger={setPopupOpen}>
                                        <div className="popup__content">
                                            <div className="input-wrapper">
                                                <input className="input" placeholder={t("name-ip")} type="text" value={newWhitelistEntryIP} onChange={(e) => (setNewWhitelistEntryIP(e.target.value))} />
                                            </div>
                                        </div>
                                        <div className="popup__footer">
                                            <button className="primary-btn" disabled={!isActiveButtonNewWhitelistEntry} onClick={handleWhitelistEntryAdd}>{t("button-whitelist-entry-add")}</button>
                                        </div>
                                    </Popup>
                                </>
                                : ''
                        }
                    </>
            }
        </>
    );
};