const loginUser = async (email, password) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/user/login`;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });

        return response;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default loginUser;
