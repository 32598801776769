import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import './LandingLayout.css';

import LandingHeader from '../LandingHeader';
import LandingFooter from '../LandingFooter';
import Preloader from '../Preloader';
import TelegramButton from '../TelegramButton';

const LandingLayout = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const images = document.querySelectorAll('img');
        
        if (images.length === 0) {
            setIsLoading(false);
            return;
        }

        let loadedImagesCount = 0;
        const checkAllImagesLoaded = () => {
            loadedImagesCount += 1;
            if (loadedImagesCount === images.length) {
                setIsLoading(false);
            }
        };

        images.forEach((img) => {
            if (img.complete) {
                checkAllImagesLoaded();
            } else {
                img.addEventListener('load', checkAllImagesLoaded);
                img.addEventListener('error', checkAllImagesLoaded);
            }
        });

        return () => {
            images.forEach((img) => {
                img.removeEventListener('load', checkAllImagesLoaded);
                img.removeEventListener('error', checkAllImagesLoaded);
            });
        };
    }, []);

    return (
        <div className="landing-layout">
            {isLoading ? (
                <Preloader />
            ) : (
                <>
                    <LandingHeader />
                    <main>
                        <Outlet />
                    </main>
                    <LandingFooter />
                    <TelegramButton/>
                </>
            )}
        </div>
    );
};

export default LandingLayout;
