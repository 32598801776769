import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../store/shopSlice';
import { fetchAvailableOrderTypes } from '../../store/availableOrderTypesSlice';

export const AccountHomePage = () => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.shop);

    const [hasResidentialProxyProductPool1, setHasResidentialProxyProductPool1] = useState(false)
    const [hasResidentialProxyProductPool2, setHasResidentialProxyProductPool2] = useState(false)


    useEffect(() => {
        dispatch(fetchProducts());
        dispatch(fetchAvailableOrderTypes(user.token));
    }, [dispatch]);

    useEffect(() => {

        if (products && products.length > 0) {
            setHasResidentialProxyProductPool1(products.some(product => product.type === 'residential-proxy-pool-1'))
            setHasResidentialProxyProductPool2(products.some(product => product.type === 'residential-proxy-pool-2'))
        }
    }, [products]);
    return (
        <>
            <Helmet>
                <title>{t("page-title-account")}</title>
            </Helmet>
            <div className="banner-list">
                {hasResidentialProxyProductPool1 && (
                    <div className="banner-item" style={{backgroundImage: "url('../../banner-image-1.svg')"}}>
                        <div className="container">
                            <h2 className="text-h2">{t('name-residential-proxy')} | Pool 1</h2>
                            <ul className="pros-list">
                                <li className="pros-list__item">
                                    <span>{t('name-residential-proxy-pool-1-pros-1')}</span>
                                </li>
                                <li className="pros-list__item">
                                    <span>{t('name-residential-proxy-pool-1-pros-2')}</span>
                                </li>
                                <li className="pros-list__item">
                                    <span>{t('name-residential-proxy-pool-1-pros-3')}</span>
                                </li>
                            </ul>
                            <Link className="primary-btn" to={'/account/shop/residential-proxy/pool-1'}>{t('button-buy-now')}</Link>
                        </div>
                    </div>
                )}
                {hasResidentialProxyProductPool2 && (
                    <div className="banner-item" style={{backgroundImage: "url('../../banner-image-2.svg')"}}>
                        <div className="container">
                            <h2 className="text-h2">{t('name-residential-proxy')} | Pool 2</h2>
                            <ul className="pros-list">
                                <li className="pros-list__item">
                                    <span>{t('name-residential-proxy-pool-2-pros-1')}</span>
                                </li>
                                <li className="pros-list__item">
                                    <span>{t('name-residential-proxy-pool-2-pros-2')}</span>
                                </li>
                                <li className="pros-list__item">
                                    <span>{t('name-residential-proxy-pool-2-pros-3')}</span>
                                </li>
                            </ul>
                            <Link className="primary-btn" to={'/account/shop/residential-proxy/pool-2'}>{t('button-buy-now')}</Link>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
