import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from "../../components/BreadcrumbsComponent";
import { fetchUserData } from '../../store/userSlice';
import updateUser from "../../api/users/updateUser";

import processErrors from '../../utils/processErrors';

import { toast } from 'react-toastify';

export const ChangeDataPage = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [username, setUsername] = useState(user.data.username);
    const [email, setEmail] = useState(user.data.email);
    const [newsletter, setNewsletter] = useState(user.data.newsletter);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleChangeData = async (event) => {
        try {
            event.preventDefault();

            let changesData = {};

            if (password && password.length < 6) {
                throw new Error('error-short-password');
            }

            if (username !== user.data.username) {
                changesData.username = username;
            }
            if (email !== user.data.email) {
                changesData.email = email;
            }
            if (newsletter !== user.data.newsletter) {
                changesData.newsletter = newsletter;
            }
            if (password === confirmPassword && password.length >= 6) {
                changesData.password = password;
            }

            if (Object.keys(changesData).length === 0) {
                throw new Error('error-empty-changes');
            }

            toast.info(t('notification-request-await'));

            const response = await updateUser(changesData, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            toast.success(t('notification-success'));
            dispatch(fetchUserData(user.token));
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    return (
        <>
            <Helmet>
                <title>{t("page-title-account-change-data")}</title>
            </Helmet>
            <div>
                {/* <Breadcrumbs /> */}
                <form className="form change-data-form" onSubmit={handleChangeData}>
                    <label className="label" htmlFor="username">{t('name-username')}</label>
                    <div className="input-wrapper_fade">
                        <input className="input" placeholder={t('name-username')} value={username} onChange={(e) => setUsername(e.target.value)} type="text" id="username" />
                    </div>
                    <label className="label" htmlFor="email">{t('name-email')}</label>
                    <div className="input-wrapper_fade">
                        <input className="input" placeholder={t('name-email')} value={email} onChange={(e) => setEmail(e.target.value)} type="text" id="email" />
                    </div>
                    <label className="label" htmlFor="password">{t('name-password')}</label>
                    <div className="input-wrapper_fade">
                        <input className="input" autoComplete={'off'} placeholder={t('name-password')} value={password} onChange={(e) => setPassword(e.target.value)} type="password" id="password" />
                    </div>
                    <label className="label" htmlFor="confirmPassword">{t('name-retype-password')}</label>
                    <div className="input-wrapper_fade">
                        <input className="input" placeholder={t('name-retype-password')} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" id="confirmPassword" />
                    </div>
                    <div className="checkbox-wrapper">
                        <input className="checkbox" checked={newsletter} onChange={(e) => setNewsletter(e.target.checked)} type="checkbox" id="newsletter" />
                        <label className="label" htmlFor="newsletter">{t('name-newsletter')}</label>
                    </div>
                    <button className="primary-btn" type="submit">{t('button-submit-changes')}</button>
                </form>
            </div>
        </>
    );
};
