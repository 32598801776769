import React, { useState } from 'react';
import './HintComponent.css';

const HintComponent = ({ title, content }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="hint-wrapper"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {title && <span className="hint-title">{title}</span>}
            <div className="hint-circle">?</div>
            {isHovered && <div className="hint-content">{content}</div>}
        </div>
    );
};

export default HintComponent;
