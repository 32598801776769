import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/landing.json';
import ruTranslation from './locales/ru/landing.json';

const resources = {
    en: {
        translation: enTranslation,
    },
    ru: {
        translation: ruTranslation,
    },
};

const browserLanguage = navigator.language.split('-')[0]; 

const savedLanguage = localStorage.getItem('language');


const initialLanguage = savedLanguage || (resources[browserLanguage] ? browserLanguage : 'en');

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: initialLanguage,
        interpolation: {
            escapeValue: false,
        },
    });

const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
};

export { changeLanguage };
export default i18n;
