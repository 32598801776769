const getWhitelistEntry = async (token) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/residential_proxy/pool-1/whitelist-entries`;

    try {
        const response = await fetch(apiUrl, {
            method: "get",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default getWhitelistEntry;
