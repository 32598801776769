import React from 'react';
import './PaginationComponent.css';
import { useTranslation } from "react-i18next";

const PaginationComponent = ({ meta, onPageChange }) => {
    const { t } = useTranslation();

    const { currentPage, pageSize, totalPages, totalItems } = meta;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    return (
        <div className="pagination-container">
            <div className="pagination-controls">
                <button onClick={() => handlePageChange(parseInt(currentPage) - 1)} disabled={currentPage == 1}>
                    {t("button-previous")}
                </button>
                <span>{t("name-page")} {currentPage} {t("name-of")} {totalPages}</span>
                <button onClick={() => handlePageChange(parseInt(currentPage) + 1)} disabled={currentPage == totalPages}>
                    {t("button-next")}
                </button>
            </div>
            <div className="pagination-meta">
                <span>{t("name-total-items")}: {totalItems}</span>
                <span> | {t("name-page-items")}: {pageSize}</span>
            </div>
        </div>
    );
};

export default PaginationComponent;
