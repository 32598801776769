import React from 'react';
import { Outlet } from 'react-router-dom';
import './ProfileLayout.css';

import ProfileHeader from '../ProfileHeader';
import ProfileSidebar from '../ProfileSidebar';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProfileLayout = () => {
    return (
        <>
            <div className="profile-layout">
                <ProfileSidebar />
                <div className="profile-content">
                    <ProfileHeader />
                    <main className="profile-main">
                        <div className='container'>
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer theme="colored" />
        </>
    );
};

export default ProfileLayout;
