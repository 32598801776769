import React, { useState, useEffect } from 'react';
import './LandingFooter.css';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';

import sendView from '../../api/view/sendView';

const LandingFooter = () => {
    const { t, i18n } = useTranslation();

    const fetchSendView = async () => {
        await sendView("landing");
    }

    useEffect(() => {
        fetchSendView();
    }, []);

    return (
        <header className="landing-footer">
            <div className="container">
                <Link className="landing-footer__logo" to={'/'}>
                    <img src='/logo.svg'></img>
                </Link>
                <nav className='landing-footer-nav'>
                    <Link className='landing-footer-nav__item' to={'/prices'}>{t("name-prices-page")}</Link>
                    <Link className='landing-footer-nav__item' to={'/countries'}>{t("name-countries-page")}</Link>
                    <Link className='landing-footer-nav__item' to={'/privacy-policy'}>{t("name-privacy-page")}</Link>
                    <Link className='landing-footer-nav__item' to={'/terms-and-conditions'}>{t("name-conditions-page")}</Link>
                    <Link className='landing-footer-nav__item' to={'/contacts'}>{t("name-contacts-page")}</Link>
                </nav>
            </div>
        </header>
    );
};

export default LandingFooter;
