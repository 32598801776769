import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authUser } from '../store/userSlice';

import processErrors from '../utils/processErrors';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const LoginPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const auth = useSelector((state) => state.user);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            localStorage.setItem('authToken', `Bearer ${token}`);

            toast.success(t('notification-login-success'));
            setTimeout(() => window.location.href = "/account", 1000);
        }
    }, []);

    useEffect(() => {
        if (auth && auth?.status === 'succeeded' && auth?.token) {
            toast.success(t('notification-login-success'));
            setTimeout(() => navigate('/account'), 1000);
        } else if (auth && auth.status === 'failed') {
            const errorMessage = processErrors(auth.error);

            toast.error(t(errorMessage));
        }
    }, [auth, navigate, t]);

    const handleLogin = (event) => {
        event.preventDefault();
        try {
            if (!email) {
                throw new Error('error-empty-email');
            } else if (!password) {
                throw new Error('error-empty-password');
            }

            toast.info(t('notification-request-await'));
            dispatch(authUser({ email, password }));
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    useEffect(() => {
        if (auth && auth?.isAuthenticated) {
            toast.success(t('notification-login-success'));
            setTimeout(() => navigate('/account'), 1000);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{t("page-title-login")}</title>
                <meta name="description" content={t("page-description-login")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-login")} />
                <meta property="og:description" content={t("page-description-login")} />
            </Helmet>
            <div className="auth-page">
                <main className="auth-main">
                    <div className="auth-main__side">
                        <div className="container">
                            <h1 className="auth-h1">{t('auth-welcome')}</h1>
                            <p className="auth-p">{t('auth-register-hint')}</p>
                            <Link className="primary-btn" to={"/register"}>{t('button-create-account')}</Link>
                        </div>
                    </div>
                    <div className="auth-main__side">
                        <Link className="logo" to='/'>
                            <img src="/logo.svg" alt="" />
                        </Link>
                        <p className="auth-p" style={{ marginBottom: '43px' }}>{t('name-authorization')}</p>
                        <form onSubmit={handleLogin}>
                            <div className="input-wrapper">
                                <div className="input-wrapper__icon">
                                    <img src="/mail.svg" alt="" />
                                </div>
                                <input
                                    className="input"
                                    type="text"
                                    id="username"
                                    value={email}
                                    placeholder={t("name-email")}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-wrapper">
                                <div className="input-wrapper__icon">
                                    <img src="/lock.svg" alt="" />
                                </div>
                                <input
                                    className="input"
                                    type="password"
                                    id="password"
                                    placeholder={t("name-password")}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button className="primary-btn" type="submit">{t('button-login')}</button>
                            <Link className="auth-link" to={"/forgot-password"} style={{ display: 'block', margin: '23px auto' }}>{t('name-forgot-name-or-password')}</Link>
                            <p className="auth-p" style={{ marginTop: '25px' }}>{t('name-auth-social')}</p>
                            <div className="auth-social-list">
                                <a className="auth-social-list__item" href={process.env.REACT_APP_DOMAIN_URL + '/api/auth/google'}>
                                    <img src="/icons8-google-48.png" alt="" />
                                </a>
                            </div>
                        </form>
                    </div>
                </main>
                <ToastContainer theme="colored" />
            </div>
        </>
    );
};
