import {configureStore} from "@reduxjs/toolkit";
import userReducer from "./userSlice"
import shopReducer from "./shopSlice"
import availableOrderTypesReducer from "./availableOrderTypesSlice"


const store = configureStore({
    reducer: {
        user: userReducer,
        shop: shopReducer,
        availableOrderTypes: availableOrderTypesReducer
    }
})

export default store;