import React, { useState, useEffect, useRef } from 'react';
import './LanguageSwitcher.css';
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../i18n";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);
    const switcherRef = useRef(null);

    const getLanguageName = (lang) => {
        switch (lang) {
            case 'en':
                return 'English';
            case 'ru':
                return 'Русский';
            default:
                return 'Language';
        }
    };

    const handleToggle = () => {
        setIsOpened(!isOpened);
    };

    const handleChangeLanguage = (lang) => {
        changeLanguage(lang);
        setIsOpened(false); 
    };

    const handleClickOutside = (event) => {
        if (switcherRef.current && !switcherRef.current.contains(event.target)) {
            setIsOpened(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`language-switcher ${isOpened ? 'opened' : ''}`} ref={switcherRef}>
            <div className='language-switcher__header' onClick={handleToggle}>
                <img src='/planet.svg' alt='language' />
                <span className='language-switcher__title'>{getLanguageName(i18n.language)}</span>
                <img className='language-switcher__arrow' src='/dropdown-arrow.svg' alt='dropdown' />
            </div>
            {isOpened && (
                <div className='sub-menu'>
                    <div className='sub-list'>
                        <div
                            className={`sub-list__item ${i18n.language === 'en' ? 'selected' : ''}`}
                            onClick={() => handleChangeLanguage('en')}
                        >
                            English
                        </div>
                        <div
                            className={`sub-list__item ${i18n.language === 'ru' ? 'selected' : ''}`}
                            onClick={() => handleChangeLanguage('ru')}
                        >
                            Русский
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LanguageSwitcher;
