import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import Slider from 'rc-slider';
import TooltipSlider from '../components/TooltipSlider.tsx';
import Preloader from '../components/Preloader';

import getProducts from "../api/products/getProducts.js";

export const PricesPage = () => {
    const { t } = useTranslation();

    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [minValue, setMinValue] = useState(1);
    const [maxValue, setMaxValue] = useState(100);
    const [customValue, setCustomValue] = useState("");


    const fetchProducts = async () => {
        const data = await getProducts("residential-proxy-pool-1");
        setProducts(data);
        setSelectedProduct(data[0]);

        const traffics = data.map(item => parseInt(item.data.traffic));
        const minTraffic = Math.min(...traffics);
        const maxTraffic = Math.max(...traffics);

        setMinValue(minTraffic);
        setMaxValue(maxTraffic);
    };

    const handleCustomValueChange = (value) => {
        const parsedValue = parseInt(value);
        if (isNaN(parsedValue)) return;

        const filteredProduct = products
            .sort((a, b) => parseInt(a.data.traffic) - parseInt(b.data.traffic))
            .filter((item) => parsedValue >= parseInt(item.data.traffic))
            .pop();

        setSelectedProduct(filteredProduct);
        console.log(filteredProduct);

        if (filteredProduct) {
            const price = Math.ceil(parsedValue * filteredProduct.data.price_per_gb * 100) / 100;
            setCustomValue(`${parsedValue} GB | $ ${price}`);
        } else {
            setCustomValue(`${parsedValue} GB | $0`);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t("page-title-prices")}</title>
                <meta name="description" content={t("page-description-prices")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-prices")} />
                <meta property="og:description" content={t("page-description-prices")} />
            </Helmet>
            {
                products && products?.length > 0 ?
                    <>
                        <section className="prices-section">
                            <div className="container">
                                <h1 className="section-h2">{t('name-prices-section-title')}</h1>
                                <p className="section-p">{t('name-prices-section-text')}</p>
                                <div className="prices-calculator">
                                    <div className="container">
                                        <div className="grid-wrapper">
                                            <div>
                                                <TooltipSlider
                                                    min={minValue}
                                                    max={maxValue}
                                                    tipFormatter={() => customValue}
                                                    onChange={handleCustomValueChange}
                                                />
                                                <div className="prices-calculator__panel">
                                                    <div>
                                                        <div className="summary-list">
                                                            <div className="summary-list__item">
                                                                <div className="summary-list__name">{t("name-price")}</div>
                                                                <div className="summary-list__value">${selectedProduct?.data?.price_per_gb} <span>/GB</span></div>
                                                            </div>
                                                            <div className="summary-list__item">
                                                                <div className="summary-list__name">{t("name-discount")}</div>
                                                                <div className="summary-list__value">-{selectedProduct?.data?.discount}%</div>
                                                            </div>
                                                        </div>
                                                        <Link className="primary-btn" to={"/account/orders/residential-proxy/pool-1"}>{t("button-buy-now")}</Link>
                                                    </div>
                                                    <div className="separator"></div>
                                                    <div>
                                                        <h3 className="text-h3">{t("name-payment-types")}</h3>
                                                        <div className="payment-types">
                                                            <div className="payment-types__item">
                                                                <img src="/btc.svg" alt="" />
                                                                <span>BTC</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/eth.svg" alt="" />
                                                                <span>ETH</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/ltc.svg" alt="" />
                                                                <span>LTC</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/usdt.svg" alt="" />
                                                                <span>USDT</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/avax.svg" alt="" />
                                                                <span>AVAX</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/bch.svg" alt="" />
                                                                <span>BCH</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/bnb.svg" alt="" />
                                                                <span>BNB</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/dai.svg" alt="" />
                                                                <span>DAI</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/dash.svg" alt="" />
                                                                <span>DASH</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/doge.svg" alt="" />
                                                                <span>DOGE</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/sol.svg" alt="" />
                                                                <span>SOL</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/ton.svg" alt="" />
                                                                <span>TON</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/trx.svg" alt="" />
                                                                <span>TRX</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/usdc.svg" alt="" />
                                                                <span>USDC</span>
                                                            </div>
                                                            <div className="payment-types__item">
                                                                <img src="/matic.svg" alt="" />
                                                                <span>MATIC</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="prices-calculator__panel">
                                                <div className="prices-calculator__pros-list">
                                                    <div className="prices-calculator__pros-list__item">
                                                        {t('name-calculator-pros-1')}
                                                    </div>
                                                    <div className="prices-calculator__pros-list__item">
                                                        {t('name-calculator-pros-2')}
                                                    </div>
                                                    <div className="prices-calculator__pros-list__item">
                                                        {t('name-calculator-pros-3')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="functional-section">
                            <div className="container">
                                <h2 className="section-h2">{t("name-functional-title-section")}</h2>
                                <ul className="functional-list">
                                    <li className="functional-list__item">
                                        <h3 className="functional-list__h3">{t("name-functional-list-title-1")}</h3>
                                        <p className="functional-list__p">{t("name-functional-list-text-1")}</p>
                                        <img className="functional-list__icon" src="/functional-icon-1.svg" alt="" />
                                    </li>
                                    <li className="functional-list__item">
                                        <h3 className="functional-list__h3">{t("name-functional-list-title-2")}</h3>
                                        <p className="functional-list__p">{t("name-functional-list-text-2")}</p>
                                        <img className="functional-list__icon" src="/functional-icon-2.svg" alt="" />
                                    </li>
                                    <li className="functional-list__item">
                                        <h3 className="functional-list__h3">{t("name-functional-list-title-3")}</h3>
                                        <p className="functional-list__p">{t("name-functional-list-text-3")}</p>
                                        <img className="functional-list__icon" src="/functional-icon-3.svg" alt="" />
                                    </li>
                                    <li className="functional-list__item">
                                        <h3 className="functional-list__h3">{t("name-functional-list-title-4")}</h3>
                                        <p className="functional-list__p">{t("name-functional-list-text-4")}</p>
                                        <img className="functional-list__icon" src="/functional-icon-4.svg" alt="" />
                                    </li>
                                    <li className="functional-list__item">
                                        <h3 className="functional-list__h3">{t("name-functional-list-title-5")}</h3>
                                        <p className="functional-list__p">{t("name-functional-list-text-5")}</p>
                                        <img className="functional-list__icon" src="/functional-icon-5.svg" alt="" />
                                    </li>
                                    <li className="functional-list__item">
                                        <h3 className="functional-list__h3">{t("name-functional-list-title-6")}</h3>
                                        <p className="functional-list__p">{t("name-functional-list-text-6")}</p>
                                        <img className="functional-list__icon" src="/functional-icon-6.svg" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section className="solutions-section">
                            <div className="container">
                                <h2 className="section-h2">{t("name-solutions-section-title")}</h2>
                                <p className="section-p">{t("name-solutions-section-text")}</p>
                                <ul className="solutions-list">
                                    <li className="solutions-list__item">
                                        <div className="solutions-list__icon">
                                            <img src="/solutions-icon-1.svg" alt="" />
                                        </div>
                                        <h3 className="solutions-list__h3">{t("name-solutions-item-1-title")}</h3>
                                        <p className="solutions-list__p">{t("name-solutions-item-1-text")}</p>
                                    </li>
                                    <li className="solutions-list__item">
                                        <div className="solutions-list__icon">
                                            <img src="/solutions-icon-2.svg" alt="" />
                                        </div>
                                        <h3 className="solutions-list__h3">{t("name-solutions-item-2-title")}</h3>
                                        <p className="solutions-list__p">{t("name-solutions-item-2-text")}</p>
                                    </li>
                                    <li className="solutions-list__item">
                                        <div className="solutions-list__icon">
                                            <img src="/solutions-icon-3.svg" alt="" />
                                        </div>
                                        <h3 className="solutions-list__h3">{t("name-solutions-item-3-title")}</h3>
                                        <p className="solutions-list__p">{t("name-solutions-item-3-text")}</p>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section className="cabinet-section">
                            <div className="container">
                                <h2 className="section-h2">{t("name-cabinet-section-title")}</h2>
                                <div className="cabinet-section__wrapper">
                                    <div>
                                        <p className="cabinet-section__p">{t("name-cabinet-section-text")}</p>
                                        <ul className="pros-list">
                                            <li className="pros-list__item">
                                                {t("name-cabinet-item-1-title")}
                                            </li>
                                            <li className="pros-list__item">
                                                {t("name-cabinet-item-2-title")}
                                            </li>
                                            <li className="pros-list__item">
                                                {t("name-cabinet-item-3-title")}
                                            </li>
                                        </ul>
                                        <Link className="primary-btn" to={'/register'}>{t("button-register")}</Link>
                                    </div>
                                    <div className="cabinet-section__preview">
                                        <img src="/lk_screenshoot.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </> :
                    <Preloader />
            }
        </>
    );
};
