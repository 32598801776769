import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import './InputTagsComponent.css';

const InputTagsComponent = ({ value, onChange }) => {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState("");
    const [numbers, setNumbers] = useState([]);

    useEffect(() => {
        if (onChange) {
            onChange(numbers.join(","));
        }
    }, [numbers, onChange]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" || e.key === ",") {
            e.preventDefault();
            const newNumbers = inputValue
                .split(",")
                .map((num) => num.trim())
                .filter((num) => num && !isNaN(num) && !numbers.includes(num));

            setNumbers([...numbers, ...newNumbers]);
            setInputValue("");
        }
    };

    const removeNumber = (numToRemove) => {
        setNumbers(numbers.filter((num) => num !== numToRemove));
    };

    useEffect(() => {
        if (value !== undefined) {
            const externalNumbers = value
                .split(",")
                .map((num) => num.trim())
                .filter((num) => num && !isNaN(num));
            setNumbers(externalNumbers);
        }
    }, [value]);

    return (
        <div className="input-wrapper_fade">
            <input
                className="input"
                value={inputValue}
                placeholder={t("name-input-separated-numbers")}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                type="text"
            />
            <div className="tags-container">
                {numbers.map((num, index) => (
                    <div key={index} className="tag">
                        {num}
                        <button className="remove-button" onClick={() => removeNumber(num)}>
                            ×
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InputTagsComponent;
