const getProducts = async (product_type) => {
    const baseUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/products`;
    let apiUrl = baseUrl;

    const queries = [];
    if (product_type) {
        queries.push(`product_type=${product_type}`);
    }

    if (queries.length > 0) {
        apiUrl = `${baseUrl}?${queries.join('&')}`;
    }

    try {
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default getProducts;
