import React from "react";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

export const CountriesPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("page-title-countries")}</title>
                <meta name="description" content={t("page-description-countries")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-countries")} />
                <meta property="og:description" content={t("page-description-countries")} />
            </Helmet>
            <section className="places-section">
                <div className="container">
                    <h2 className="section-h2">{t('name-places-section-title')}</h2>
                    <ul className="countries-list">
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-usa.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-1')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-1')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-germany.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-2')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-2')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-britain.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-3')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-3')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-france.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-4')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-4')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-canada.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-5')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-5')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-brasil.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-6')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-6')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-spain.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-7')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-7')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-italy.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-8')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-8')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-vietnam.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-9')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-9')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-philipines.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-10')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-10')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-poland.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-11')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-11')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-japan.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-12')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-12')}</p>
                        </div>
                    </ul>
                </div>
            </section>
            <section className="all-countries-section">
                <div className="container">
                    <div className="panel_bordered">
                        <div className="container">
                            <h2 className="section-h2">{t("name-africa")}</h2>
                            <ol className="text-list">
                                <li>{t("name-algeria")}</li>
                                <li>{t("name-angola")}</li>
                                <li>{t("name-benin")}</li>
                                <li>{t("name-botswana")}</li>
                                <li>{t("name-burkina_faso")}</li>
                                <li>{t("name-burundi")}</li>
                                <li>{t("name-cameroon")}</li>
                                <li>{t("name-central_african_republic")}</li>
                                <li>{t("name-equatorial_guinea")}</li>
                                <li>{t("name-democratic_republic_of_the_congo")}</li>
                                <li>{t("name-djibouti")}</li>
                                <li>{t("name-egypt")}</li>
                                <li>{t("name-gambia")}</li>
                                <li>{t("name-eritrea")}</li>
                                <li>{t("name-ethiopia")}</li>
                                <li>{t("name-gabon")}</li>
                                <li>{t("name-ivory_coast")}</li>
                                <li>{t("name-ghana")}</li>
                                <li>{t("name-guinea")}</li>
                                <li>{t("name-guinea_bissau")}</li>
                                <li>{t("name-libya")}</li>
                                <li>{t("name-kenya")}</li>
                                <li>{t("name-lesotho")}</li>
                                <li>{t("name-liberia")}</li>
                                <li>{t("name-mauritania")}</li>
                                <li>{t("name-madagascar")}</li>
                                <li>{t("name-malawi")}</li>
                                <li>{t("name-mali")}</li>
                                <li>{t("name-niger")}</li>
                                <li>{t("name-morocco")}</li>
                                <li>{t("name-mozambique")}</li>
                                <li>{t("name-namibia")}</li>
                                <li>{t("name-senegal")}</li>
                                <li>{t("name-nigeria")}</li>
                                <li>{t("name-republic_of_the_congo")}</li>
                                <li>{t("name-rwanda")}</li>
                                <li>{t("name-south_africa")}</li>
                                <li>{t("name-sierra_leone")}</li>
                                <li>{t("name-somalia")}</li>
                                <li>{t("name-somaliland")}</li>
                                <li>{t("name-tanzania")}</li>
                                <li>{t("name-south_sudan")}</li>
                                <li>{t("name-sudan")}</li>
                                <li>{t("name-eswatini")}</li>
                                <li>{t("name-zambia")}</li>
                                <li>{t("name-zimbabwe")}</li>
                                <li>{t("name-tunisia")}</li>
                                <li>{t("name-uganda")}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="panel_bordered">
                        <div className="container">
                            <h2 className="section-h2">{t("name-asia")}</h2>
                            <ol className="text-list">
                                <li>{t("name-afghanistan")}</li>
                                <li>{t("name-armenia")}</li>
                                <li>{t("name-azerbaijan")}</li>
                                <li>{t("name-bangladesh")}</li>
                                <li>{t("name-bhutan")}</li>
                                <li>{t("name-brunei")}</li>
                                <li>{t("name-cambodia")}</li>
                                <li>{t("name-china")}</li>
                                <li>{t("name-east_timor")}</li>
                                <li>{t("name-hong_kong")}</li>
                                <li>{t("name-india")}</li>
                                <li>{t("name-indonesia")}</li>
                                <li>{t("name-iran")}</li>
                                <li>{t("name-iraq")}</li>
                                <li>{t("name-israel")}</li>
                                <li>{t("name-japan")}</li>
                                <li>{t("name-jordan")}</li>
                                <li>{t("name-kazakhstan")}</li>
                                <li>{t("name-kuwait")}</li>
                                <li>{t("name-kyrgyzstan")}</li>
                                <li>{t("name-laos")}</li>
                                <li>{t("name-lebanon")}</li>
                                <li>{t("name-malaysia")}</li>
                                <li>{t("name-maldives")}</li>
                                <li>{t("name-mongolia")}</li>
                                <li>{t("name-myanmar")}</li>
                                <li>{t("name-nepal")}</li>
                                <li>{t("name-oman")}</li>
                                <li>{t("name-pakistan")}</li>
                                <li>{t("name-palestine")}</li>
                                <li>{t("name-philippines")}</li>
                                <li>{t("name-qatar")}</li>
                                <li>{t("name-saudi_arabia")}</li>
                                <li>{t("name-singapore")}</li>
                                <li>{t("name-south_korea")}</li>
                                <li>{t("name-sri_lanka")}</li>
                                <li>{t("name-syria")}</li>
                                <li>{t("name-taiwan")}</li>
                                <li>{t("name-tajikistan")}</li>
                                <li>{t("name-thailand")}</li>
                                <li>{t("name-turkey")}</li>
                                <li>{t("name-turkmenistan")}</li>
                                <li>{t("name-united_arab_emirates")}</li>
                                <li>{t("name-uzbekistan")}</li>
                                <li>{t("name-vietnam")}</li>
                                <li>{t("name-yemen")}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="panel_bordered">
                        <div className="container">
                            <h2 className="section-h2">{t("name-europe")}</h2>
                            <ol className="text-list">
                                <li>{t("name-albania")}</li>
                                <li>{t("name-andorra")}</li>
                                <li>{t("name-armenia")}</li>
                                <li>{t("name-austria")}</li>
                                <li>{t("name-azerbaijan")}</li>
                                <li>{t("name-belarus")}</li>
                                <li>{t("name-belgium")}</li>
                                <li>{t("name-bosnia_and_herzegovina")}</li>
                                <li>{t("name-bulgaria")}</li>
                                <li>{t("name-croatia")}</li>
                                <li>{t("name-cyprus")}</li>
                                <li>{t("name-czech_republic")}</li>
                                <li>{t("name-denmark")}</li>
                                <li>{t("name-estonia")}</li>
                                <li>{t("name-finland")}</li>
                                <li>{t("name-france")}</li>
                                <li>{t("name-georgia")}</li>
                                <li>{t("name-germany")}</li>
                                <li>{t("name-greece")}</li>
                                <li>{t("name-hungary")}</li>
                                <li>{t("name-iceland")}</li>
                                <li>{t("name-ireland")}</li>
                                <li>{t("name-italy")}</li>
                                <li>{t("name-kazakhstan")}</li>
                                <li>{t("name-latvia")}</li>
                                <li>{t("name-liechtenstein")}</li>
                                <li>{t("name-lithuania")}</li>
                                <li>{t("name-luxembourg")}</li>
                                <li>{t("name-malta")}</li>
                                <li>{t("name-moldova")}</li>
                                <li>{t("name-monaco")}</li>
                                <li>{t("name-montenegro")}</li>
                                <li>{t("name-netherlands")}</li>
                                <li>{t("name-north_macedonia")}</li>
                                <li>{t("name-norway")}</li>
                                <li>{t("name-poland")}</li>
                                <li>{t("name-portugal")}</li>
                                <li>{t("name-romania")}</li>
                                <li>{t("name-russia")}</li>
                                <li>{t("name-san_marino")}</li>
                                <li>{t("name-serbia")}</li>
                                <li>{t("name-slovakia")}</li>
                                <li>{t("name-slovenia")}</li>
                                <li>{t("name-spain")}</li>
                                <li>{t("name-sweden")}</li>
                                <li>{t("name-switzerland")}</li>
                                <li>{t("name-turkey")}</li>
                                <li>{t("name-ukraine")}</li>
                                <li>{t("name-united_kingdom")}</li>
                                <li>{t("name-vatican_city")}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="panel_bordered">
                        <div className="container">
                            <h2 className="section-h2">{t("name-north-america")}</h2>
                            <ol className="text-list">
                                <li>{t("name-bahamas")}</li>
                                <li>{t("name-belize")}</li>
                                <li>{t("name-bermuda")}</li>
                                <li>{t("name-canada")}</li>
                                <li>{t("name-costa_rica")}</li>
                                <li>{t("name-cuba")}</li>
                                <li>{t("name-dominican_republic")}</li>
                                <li>{t("name-el_salvador")}</li>
                                <li>{t("name-greenland")}</li>
                                <li>{t("name-guatemala")}</li>
                                <li>{t("name-haiti")}</li>
                                <li>{t("name-honduras")}</li>
                                <li>{t("name-jamaica")}</li>
                                <li>{t("name-mexico")}</li>
                                <li>{t("name-nicaragua")}</li>
                                <li>{t("name-panama")}</li>
                                <li>{t("name-puerto_rico")}</li>
                                <li>{t("name-trinidad_and_tobago")}</li>
                                <li>{t("name-united_states")}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="panel_bordered">
                        <div className="container">
                            <h2 className="section-h2">{t("name-oceania")}</h2>
                            <ol className="text-list">
                                <li>{t("name-australia")}</li>
                                <li>{t("name-fiji")}</li>
                                <li>{t("name-new_caledonia")}</li>
                                <li>{t("name-new_zealand")}</li>
                                <li>{t("name-papua_new_guinea")}</li>
                                <li>{t("name-solomon_islands")}</li>
                                <li>{t("name-vanuatu")}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="panel_bordered">
                        <div className="container">
                            <h2 className="section-h2">{t("name-south-america")}</h2>
                            <ol className="text-list">
                                <li>{t("name-argentina")}</li>
                                <li>{t("name-bolivia")}</li>
                                <li>{t("name-brazil")}</li>
                                <li>{t("name-chile")}</li>
                                <li>{t("name-colombia")}</li>
                                <li>{t("name-ecuador")}</li>
                                <li>{t("name-falkland_islands")}</li>
                                <li>{t("name-french_guiana")}</li>
                                <li>{t("name-guyana")}</li>
                                <li>{t("name-paraguay")}</li>
                                <li>{t("name-peru")}</li>
                                <li>{t("name-suriname")}</li>
                                <li>{t("name-uruguay")}</li>
                                <li>{t("name-venezuela")}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};
