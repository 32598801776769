import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ChooseList.css';


const ChooseList = ({ items, onItemSelected }) => {
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        onItemSelected(item);
    };

    return (
        <ul className="choose-list">
            {
                items.map((item) => (
                    <li
                        key={item.id}
                        className={`choose-list__item ${selectedItem && selectedItem.id === item.id ? 'selected' : ''}`}
                        onClick={() => handleItemClick(item)}
                    >
                        <img className="choose-list__icon" src={item.icon} alt="" />
                        <div className="choose-list__title">{t(item.name)}</div>
                    </li>
                ))
            }
        </ul>
    );
};

export default ChooseList;
