import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import './SelectSearchComponent.css';

const SelectSearchComponent = ({ label, items, selectedItems, onItemsSelected, disabled = false, multiple = false }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const selectRef = useRef(null);

    const handleToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleSelect = (item) => {
        if (!disabled) {
            let newSelectedItems;
            if (multiple) {
                if (selectedItems.includes(item)) {
                    newSelectedItems = selectedItems.filter(i => i !== item);
                } else {
                    newSelectedItems = [...selectedItems, item];
                }
            } else {
                if (selectedItems === item) {
                    newSelectedItems = null;
                } else {
                    newSelectedItems = item;
                }
                setIsOpen(false);
            }
            onItemsSelected(newSelectedItems);
        }
    };

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredItems = items.filter(item =>
        item.name.toLowerCase().includes(searchTerm)
    );

    const renderSelectedItems = () => {
        if (multiple) {
            return selectedItems.length > 0
                ? selectedItems.map(item => item.name).join(', ')
                : t("name-select-options");
        }
        return selectedItems ? selectedItems.name : t("name-select-option");
    };

    return (
        <div className={`select-wrapper ${disabled ? 'disabled' : ''}`}>
            {label ? <label className="label">{label}</label> : ""}
            <div
                className={`select ${isOpen ? 'opened' : ''}`}
                ref={selectRef}
            >
                <div className="select__header" onClick={handleToggle}>
                    <div className="select__title">{renderSelectedItems()}</div>
                    <img className="select__arrow" src="/select-arrow.svg" alt="dropdown" />
                </div>
                {isOpen && (
                    <div className="select__content">
                        <input
                            type="text"
                            placeholder={t("name-search")}
                            value={searchTerm}
                            onChange={handleSearch}
                            className="select__search"
                        />
                        <ul className="sub-list">
                            {
                                filteredItems && filteredItems?.length > 0 ? filteredItems.map((item, index) => (
                                    <li
                                        key={index}
                                        className={`sub-list__item ${multiple ? (selectedItems.includes(item) && 'selected') : selectedItems === item ? 'selected' : ''}`}
                                        onClick={() => handleSelect(item)}
                                    >
                                        {item.name}
                                    </li>
                                ))
                                    : <div className='no-items'>{t("name-no-items")}</div>
                            }
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectSearchComponent;
