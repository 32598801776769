import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../components/BreadcrumbsComponent";
import CodeWithTabs from '../../components/CodeWithTabs';

import style from '../../styles/Support.module.scss';

export const SupportIpSkippingPage = () => {
    const { t } = useTranslation();

    const codeSnippets = [
        {
            language: 'cURL',
            code: `curl -v -x http://username123:password321_country-br_skipipslist-id_skipping_list@geo.psbproxy.io:12321 -L http://example.com`,
        },
        {
            language: 'PHP',
            code: `<?php
    // Пример запроса с использованием cURL
    $ch = curl_init();
    curl_setopt($ch, CURLOPT_URL, "http://example.com");
    curl_setopt($ch, CURLOPT_PROXY, "http://username123:password321_country-br_skipipslist-id_skipping_list@geo.psbproxy.io:12321");
    curl_exec($ch);
    curl_close($ch);
    ?>`,
        },
        {
            language: 'Python',
            code: `import requests
    
    proxies = {
        'http': 'http://username123:password321_country-br_skipipslist-id_skipping_list@geo.psbproxy.io:12321',
    }
    
    response = requests.get('http://example.com', proxies=proxies)
    print(response.text)`,
        },
        {
            language: 'Node.js',
            code: `const axios = require('axios');
    
    const config = {
        proxy: {
            host: 'geo.psbproxy.io',
            port: 12321,
            auth: {
                username: 'username123:password321_country-br_skipipslist-id_skipping_list',
            },
        },
    };
    
    axios.get('http://example.com', config).then((response) => {
        console.log(response.data);
    });`,
        },
    ];

    return (
        <>
            <Helmet>
                <title>{t("page-title-guide-ip-skipping")}</title>
                <meta name="description" content={t("page-description-guide-ip-skipping")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-guide-ip-skipping")} />
                <meta property="og:description" content={t("page-description-guide-ip-skipping")} />
            </Helmet>
            <main className={style['support-main']}>
                <div className={style['container']}>
                    <Breadcrumbs />
                    <div className={style['support-article']}>
                        <div className={style['support-article__header']}>
                            <div className={style['container']}>
                                <h1 className={style['support-article__title']}>{t("name-ip-skipping-using")}</h1>
                            </div>
                        </div>
                        <div className={style['support-article__content']}>
                            <h3>{t('ipSkippingFeature')}</h3>
                            <h4>{t('featureAvailableForPool')}</h4>
                            <p>{t('ipSkippingFeatureDescription')}</p>
                            <p>{t('enableFeature')}</p>
                            <h4>{t('nameExample')}</h4>
                            <CodeWithTabs codeSnippets={codeSnippets} />
                            <h3>{t('listCreation')}</h3>
                            <p>{t('goToPoolSection')}</p>
                            <p>{t('selectIPSkippingTab')}</p>
                            <p>{t('createNewList')}</p>
                            <p>{t('listWillAppear')}</p>
                            <img src="/ip-skipping-readme-1.png" alt={t('createNewList')} />

                            <h3>{t('editList')}</h3>
                            <p>{t('editListInstructions')}</p>
                            <img src="/ip-skipping-readme-2.png" alt={t('editList')} />
                        </div>
                        <div className={style['support-article__footer']}>
                            <div className={style['container']}></div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
