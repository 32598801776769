import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from "../../../components/BreadcrumbsComponent";
import ChooseList from "../../../components/ChooseList/index.js";
import ProductTable from "../../../components/ProductTable/index.js";
import { fetchUserData } from '../../../store/userSlice';
import { fetchAvailableOrderTypes } from '../../../store/availableOrderTypesSlice';

import getProducts from "../../../api/products/getProducts.js";
import buyProduct from "../../../api/products/buyProduct.js";

import processErrors from '../../../utils/processErrors';

import { toast } from 'react-toastify';

export const ResidentialProxyPool2Page = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [products, setProducts] = useState([]);

    const availablePaymentTypes = [
        {
            id: 0,
            name: "name-by-balance",
            value: "balance",
            icon: "/suitcase.svg"
        },
        {
            id: 1,
            name: "name-by-cryptomus",
            value: "cryptomus",
            icon: "/card.svg"
        },
        {
            id: 2,
            name: "name-by-lava",
            value: "lava",
            icon: "/cloud.svg"
        },
    ]

    const columnNames = [
        {
            name: "name-traffic",
        },
        {
            name: "name-discount",
        },
        {
            name: "name-price-for-gb",
        },
    ]

    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedPaymentType, setSelectedPaymentType] = useState();
    const [customValue, setCustomValue] = useState();

    const fetchProducts = async () => {
        const data = await getProducts("residential-proxy-pool-2");
        setProducts(data);
    };

    const handleBuySubmit = async () => {
        try {
            if (!selectedProduct) {
                throw new Error('error-empty-product');
            }
            if (!selectedPaymentType) {
                throw new Error('error-empty-payment-type');
            }

            const token = user.token;

            toast.info(t('notification-request-await'));
            const response = await buyProduct(selectedProduct.id, selectedPaymentType.value, customValue, token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            toast.success(t('notification-success-buy'));

            if (responseJson.url) {
                window.location.href = responseJson.url
            } else {
                dispatch(fetchUserData(user.token));
                dispatch(fetchAvailableOrderTypes(user.token));
            }
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    const handleCustomValueChange = async (e) => {
        const filtered_product = products
            .filter((item) => parseInt(e.target.value) >= parseInt(item.data.traffic))
            .pop();

        setSelectedProduct(filtered_product);
        setCustomValue(e.target.value);
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t("page-title-shop")}</title>
            </Helmet>
            <div>
                {/* <Breadcrumbs></Breadcrumbs> */}
                <div className="shop-wrapper">
                    <div>
                        <div className="shop-product-wrapper">
                            <h2 className="text-h2">{t('name-price-and-packet-discounts')}</h2>
                            <ProductTable columns={columnNames} products={products} onItemSelected={setSelectedProduct}></ProductTable>
                        </div>
                        <div className="shop-product-wrapper">
                            <h2 className="text-h2">{t('name-custom-packet-value')}</h2>
                            <div className="separator"></div>
                            <div className="form-section">
                                <div className="form-section__header">
                                    {/* <p className="text-p">{t("name-count-gb")}</p> */}
                                </div>
                                <div className="form-section__content">
                                    <div className="input-wrapper_fade">
                                        <input className="input" value={customValue} onChange={handleCustomValueChange} min={1} max={10000} placeholder={t("name-count-gb")} type="number" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shop-payment-form">
                            <div className="shop-payment-form__header">
                                <h2 className="text-h2">{t('name-payment-type')}</h2>
                            </div>
                            <ChooseList items={availablePaymentTypes} onItemSelected={setSelectedPaymentType}></ChooseList>
                        </div>
                    </div>
                    <div className="summary">
                        <div className="summary-header">
                            <h2 className="text-h2">{t('name-order-short-description')}</h2>
                        </div>
                        <div className="summary-content">
                            <ul className="summary-list">
                                {/* <li className="summary-list__item">
                                <span className="summary-list__name">{t('name-price')}</span>
                                <span className="summary-list__value">{selectedProduct && selectedProduct?.data?.price ? selectedProduct.data.price + '$' : '-'}</span>
                            </li> */}
                                <li className="summary-list__item">
                                    <span className="summary-list__name">{t('name-gb')}</span>
                                    <span className="summary-list__value">{selectedProduct && customValue ? customValue : selectedProduct?.data?.traffic ? selectedProduct.data.traffic : '-'}</span>
                                </li>
                                <li className="summary-list__item">
                                    <span className={`summary-list__name`}>{t('name-price-for-gb')}</span>
                                    <span className={`summary-list__value`}>{selectedProduct && selectedProduct.data?.price_per_gb ? selectedProduct.data?.price_per_gb + '$' : '-'}</span>
                                </li>
                                <li className="summary-list__item">
                                    <span className="summary-list__name">{t('name-discount')}</span>
                                    <span className="summary-list__value">{selectedProduct && selectedProduct.data?.discount ? selectedProduct.data?.discount + '%' : '-'}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="summary-footer">
                            <ul className="summary-list">
                                <li className="summary-list__item">
                                    <span className="summary-list__name">{t('name-summary')}</span>
                                    <span className={`summary-list__value bold`}>
                                        {selectedProduct ?
                                            (Math.ceil((selectedProduct.data.price_per_gb * (customValue || selectedProduct.data.traffic)) * 100) / 100).toFixed(2) + '$'
                                            : '-'}
                                    </span>
                                </li>
                            </ul>
                            <button className="primary-btn" disabled={!selectedProduct || !selectedPaymentType} onClick={handleBuySubmit}>{t('button-continue')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
