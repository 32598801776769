import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

export const HomePage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("page-title-home")}</title>
                <meta name="description" content={t("page-description-home")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-home")} />
                <meta property="og:description" content={t("page-description-home")} />
            </Helmet>
            <section className="main-section">
                <div className="container">
                    <div>
                        <h1 className="main-section__h1">{t('name-residential-proxy-best-quality')}</h1>
                        <p className="main-section__p">{t('name-residential-proxy-best-quality-text')}</p>
                        <Link className="primary-btn" to={"/prices"}>{t('button-more')}</Link>
                        <ul className="pros-list">
                            <li className="pros-list__item">
                                <p><b>99.9%</b> uptime</p>
                            </li>
                            <li className="pros-list__item">
                                <p><b>40 млн+</b> ip адресов</p>
                            </li>
                            <li className="pros-list__item">
                                <p> <b>200</b> стран</p>
                            </li>
                        </ul>
                    </div>
                    <img className="main-section__bg" src="/main-section__bg.png" alt="" />
                </div>
            </section>
            <section className="reasons-section">
                <div className="container">
                    <h2 className="section-h2">{t("name-reason-section-title")}</h2>
                    <ul className="reasons-list">
                        <li className="reasons-list__item">
                            <div>
                                <h3 className="reasons-list__h3">{t("name-reason-title-1")}</h3>
                                <p className="reasons-list__p">{t("name-reason-text-1")}</p>
                            </div>
                            <div className="reasons-list__icon">
                                <img src="/reason-lock.svg" alt="" />
                            </div>
                        </li>
                        <li className="reasons-list__item">
                            <div>
                                <h3 className="reasons-list__h3">{t("name-reason-title-2")}</h3>
                                <p className="reasons-list__p">{t("name-reason-text-2")}</p>
                            </div>
                            <div className="reasons-list__icon">
                                <img src="/reason-hammer.svg" alt="" />
                            </div>
                        </li>
                        <li className="reasons-list__item">
                            <div>
                                <h3 className="reasons-list__h3">{t("name-reason-title-3")}</h3>
                                <p className="reasons-list__p">{t("name-reason-text-3")}</p>
                            </div>
                            <div className="reasons-list__icon">
                                <img src="/reason-discount.svg" alt="" />
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="methods-section">
                <div className="container">
                    <h2 className="section-h2">{t('name-methods-section-title')}</h2>
                    <ul className="methods-list">
                        <div className="methods-list__item">
                            <div className="methods-list__icon">
                                <img src="/methods-icon-1.svg" alt="" />
                            </div>
                            <div>
                                <h3 className="methods-list__h3">{t("name-methods-title-1")}</h3>
                                <p className="methods-list__p">{t("name-methods-text-1")}</p>
                            </div>
                        </div>
                        <div className="methods-list__item">
                            <div className="methods-list__icon">
                                <img src="/methods-icon-2.svg" alt="" />
                            </div>
                            <div>
                                <h3 className="methods-list__h3">{t("name-methods-title-2")}</h3>
                                <p className="methods-list__p">{t("name-methods-text-2")}</p>
                            </div>
                        </div>
                        <div className="methods-list__item">
                            <div className="methods-list__icon">
                                <img src="/methods-icon-3.svg" alt="" />
                            </div>
                            <div>
                                <h3 className="methods-list__h3">{t("name-methods-title-3")}</h3>
                                <p className="methods-list__p">{t("name-methods-text-3")}</p>
                            </div>
                        </div>
                        <div className="methods-list__item">
                            <div className="methods-list__icon">
                                <img src="/methods-icon-4.svg" alt="" />
                            </div>
                            <div>
                                <h3 className="methods-list__h3">{t("name-methods-title-4")}</h3>
                                <p className="methods-list__p">{t("name-methods-text-4")}</p>
                            </div>
                        </div>
                        <div className="methods-list__item">
                            <div className="methods-list__icon">
                                <img src="/methods-icon-5.svg" alt="" />
                            </div>
                            <div>
                                <h3 className="methods-list__h3">{t("name-methods-title-5")}</h3>
                                <p className="methods-list__p">{t("name-methods-text-5")}</p>
                            </div>
                        </div>
                        <div className="methods-list__item">
                            <div className="methods-list__icon">
                                <img src="/methods-icon-6.svg" alt="" />
                            </div>
                            <div>
                                <h3 className="methods-list__h3">{t("name-methods-title-6")}</h3>
                                <p className="methods-list__p">{t("name-methods-text-6")}</p>
                            </div>
                        </div>
                    </ul>
                </div>
            </section>
            <section className="places-section">
                <div className="container">
                    <h2 className="section-h2">{t('name-places-section-title')}</h2>
                    <ul className="countries-list">
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-usa.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-1')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-1')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-germany.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-2')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-2')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-britain.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-3')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-3')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-france.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-4')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-4')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-canada.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-5')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-5')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-brasil.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-6')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-6')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-spain.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-7')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-7')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-italy.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-8')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-8')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-vietnam.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-9')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-9')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-philipines.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-10')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-10')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-poland.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-11')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-11')}</p>
                        </div>
                        <div className="countries-list__item">
                            <img className="countries-list__icon" src="/flag-japan.svg" alt="" />
                            <h3 className="countries-list__h3">{t('name-countries-title-12')}</h3>
                            <p className="countries-list__p">{t('name-countries-text-12')}</p>
                        </div>
                    </ul>
                    <Link className="primary-btn" to={"/countries"}>{t("button-all-places")}</Link>
                </div>
            </section>
        </>
    );
};
