import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import processErrors from '../utils/processErrors';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import resetPassword from "../api/users/resetPassword.js";

export const ResetPasswordPage = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSended, setIsSended] = useState(false);

    const handleResetPassword = async (event) => {
        try {
            event.preventDefault();

            const params = new URLSearchParams(location.search);
            const token = params.get('token');

            if (password !== confirmPassword) {
                throw new Error('error-passwords-not-match');
            }
            if (!token) {
                throw new Error('error-token-is-none');
            }

            toast.info(t('notification-request-await'));
            const response = await resetPassword(password, token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            setIsSended(true)
            toast.success(t('notification-success'));
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    return (
        <>
            <Helmet>
                <title>{t("page-title-reset-password")}</title>
                <meta name="description" content={t("page-description-reset-password")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-reset-password")} />
                <meta property="og:description" content={t("page-description-reset-password")} />
            </Helmet>
            <div className="auth-page">
                <main className="auth-main">
                    <div className="auth-main__side">
                        <div className="container">
                            <h1 className="auth-h1">{t('auth-welcome')}</h1>
                            <p className="auth-p">{t('auth-access-hint')}</p>
                            <Link className="primary-btn" to={"/login"}>{t('button-login')}</Link>
                        </div>
                    </div>
                    <div className="auth-main__side">
                        <Link className="logo" to='/'>
                            <img src="/logo.svg" alt="" />
                        </Link>
                        {
                            !isSended ?
                                <p className="auth-p" style={{marginBottom: "43px"}}>{t('name-change-password')}</p>
                                : ''
                        }
                        {
                            !isSended ?
                                <form onSubmit={handleResetPassword}>
                                    <div className="input-wrapper">
                                        <div className="input-wrapper__icon">
                                            <img src="/lock.svg" alt="" />
                                        </div>
                                        <input
                                            className="input"
                                            type="password"
                                            id="password"
                                            placeholder={t("name-password")}
                                            value={password}
                                            minLength={6}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="input-wrapper">
                                        <div className="input-wrapper__icon">
                                            <img src="/lock.svg" alt="" />
                                        </div>
                                        <input
                                            className="input"
                                            type="password"
                                            id="password"
                                            placeholder={t("name-retype-password")}
                                            value={confirmPassword}
                                            minLength={6}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button className="primary-btn" type="submit">{t('button-change-password')}</button>
                                </form> :
                                <div>
                                    <p className="auth-p">{t('name-password-changed')}</p>
                                </div>
                        }
                    </div>
                </main>
                <ToastContainer theme="colored" />
            </div>
        </>
    );
};
