import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';

import './CodeWithTabs.css';

const CodeWithTabs = ({ codeSnippets }) => {
    const [activeTab, setActiveTab] = useState(codeSnippets[0].language);
    const [copied, setCopied] = useState(false);

    const handleTabClick = (language) => {
        setActiveTab(language);
        setCopied(false);
    };

    const activeSnippet = codeSnippets.find(snippet => snippet.language === activeTab);

    return (
        <div className="code-tabs-container">
            <div className="tabs">
                {codeSnippets.map((snippet) => (
                    <button
                        key={snippet.language}
                        className={snippet.language === activeTab ? 'active' : ''}
                        onClick={() => handleTabClick(snippet.language)}
                    >
                        {snippet.language}
                    </button>
                ))}
            </div>

            <div className="code-snippet">
                <SyntaxHighlighter language={activeSnippet.language.toLowerCase()} style={okaidia}>
                    {activeSnippet.code}
                </SyntaxHighlighter>

                <CopyToClipboard text={activeSnippet.code} onCopy={() => setCopied(true)}>
                    <button className="copy-button">
                        {copied ? 'Copied!' : 'Copy'}
                    </button>
                </CopyToClipboard>
            </div>
        </div>
    );
};

export default CodeWithTabs;