import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../components/BreadcrumbsComponent";
import CodeWithTabs from '../../components/CodeWithTabs';

import style from '../../styles/Support.module.scss';

export const SupportPool1Terms = () => {
    const { t } = useTranslation();

    const codeSnippets = [
        {
            language: 'cURL',
            code: `curl -v -x http://username123:password321_country-br_skipipslist-id_skipping_list@geo.psbproxy.io:12321 -L http://example.com`,
        },
        {
            language: 'PHP',
            code: `<?php
    // Пример запроса с использованием cURL
    $ch = curl_init();
    curl_setopt($ch, CURLOPT_URL, "http://example.com");
    curl_setopt($ch, CURLOPT_PROXY, "http://username123:password321_country-br_skipipslist-id_skipping_list@geo.psbproxy.io:12321");
    curl_exec($ch);
    curl_close($ch);
    ?>`,
        },
        {
            language: 'Python',
            code: `import requests
    
    proxies = {
        'http': 'http://username123:password321_country-br_skipipslist-id_skipping_list@geo.psbproxy.io:12321',
    }
    
    response = requests.get('http://example.com', proxies=proxies)
    print(response.text)`,
        },
        {
            language: 'Node.js',
            code: `const axios = require('axios');
    
    const config = {
        proxy: {
            host: 'geo.psbproxy.io',
            port: 12321,
            auth: {
                username: 'username123:password321_country-br_skipipslist-id_skipping_list',
            },
        },
    };
    
    axios.get('http://example.com', config).then((response) => {
        console.log(response.data);
    });`,
        },
    ];

    return (
        <>
            <Helmet>
                <title>{t("page-title-guide-terms")}</title>
                <meta name="description" content={t("page-description-guide-terms")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-guide-terms")} />
                <meta property="og:description" content={t("page-description-guide-terms")} />
            </Helmet>
            <main className={style['support-main']}>
                <div className={style['container']}>
                    <Breadcrumbs />
                    <div className={style['support-article']}>
                        <div className={style['support-article__header']}>
                            <div className={style['container']}>
                                <h1 className={style['support-article__title']}>{t("name-pool-1-terms")}</h1>
                            </div>
                        </div>
                        <div className={style['support-article__content']}>
                            <h2>{ t("location-pool-1-term-location") }</h2>
                            <p>
                                { t("location-pool-1-term-location-description") }
                            </p>

                            <h3>{ t("location-pool-1-term-region") }</h3>
                            <p><strong>{ t("location-pool-1-term-region-key") }</strong> { t("location-pool-1-term-region-description") }</p>

                            <h4>{ t("location-pool-1-term-supported-regions") }</h4>
                            <ul>
                                <li>africa</li>
                                <li>arabstates</li>
                                <li>asiapacific</li>
                                <li>europe</li>
                                <li>middleeast</li>
                                <li>northamerica</li>
                                <li>southlatinamerica</li>
                            </ul>

                            <h3>{ t("location-pool-1-term-country") }</h3>
                            <p><strong>{ t("location-pool-1-term-country-key") }</strong> { t("location-pool-1-term-country-description") }</p>

                            <h3>{ t("location-pool-1-term-city") }</h3>
                            <p><strong>{ t("location-pool-1-term-city-key") }</strong> { t("location-pool-1-term-city-description") }</p>
                            <p>{ t("location-pool-1-term-city-country-note") }</p>

                            <h3>{ t("location-pool-1-term-state") }</h3>
                            <p><strong>{ t("location-pool-1-term-state-key") }</strong> { t("location-pool-1-term-state-description") }</p>
                            <p>{ t("location-pool-1-term-state-country-note") }</p>

                            <h3>{ t("location-pool-1-term-isp") }</h3>
                            <p><strong>{ t("location-pool-1-term-isp-key") }</strong> { t("location-pool-1-term-isp-description") }</p>
                            <p>{ t("location-pool-1-term-isp-city-note") }</p>

                            <h2>{ t("rotation-pool-1-term-rotation") }</h2>
                            <p>{ t("rotation-pool-1-term-rotation-description") }</p>

                            <h3>{ t("rotation-pool-1-term-sticky") }</h3>
                            <p>{ t("rotation-pool-1-term-sticky-description") }</p>

                            <p>{ t("rotation-pool-1-term-session-key") } { t("rotation-pool-1-term-session-description") }</p>
                            <p>{ t("rotation-pool-1-term-lifetime-key") } { t("rotation-pool-1-term-lifetime-description") }</p>

                            <h3>{ t("rotation-pool-1-term-randomize") }</h3>
                            <p>{ t("rotation-pool-1-term-randomize-description") }</p>
                            <p>{ t("rotation-pool-1-term-randomize-note") }</p>

                            <h2>{ t("highend-pool-1-term-highendpool") }</h2>
                            <p>{ t("highend-pool-1-term-highendpool-description") }</p>

                            <p>{ t("highend-pool-1-term-highendpool-activation") }</p>

                            <h2>{ t("skip-isp-static-pool-1-term-skipispstatic") }</h2>
                            <p>{ t("skip-isp-static-pool-1-term-skipispstatic-description") }</p>

                            <p>{ t("skip-isp-static-pool-1-term-skipispstatic-activation") }</p>

                            <h2>{ t("ip-whitelisting-pool-1-term-ipwhitelisting") }</h2>
                            <p>{ t("ip-whitelisting-pool-1-term-ipwhitelisting-description") }</p>

                            <h3>{ t("ip-whitelisting-pool-1-term-benefits") }</h3>
                            <p>{ t("ip-whitelisting-pool-1-term-benefits-description") }</p>

                            <ul>
                                <li>{ t("ip-whitelisting-pool-1-term-benefits-security") }</li>
                                <li>{ t("ip-whitelisting-pool-1-term-benefits-access") }</li>
                                <li>{ t("ip-whitelisting-pool-1-term-benefits-abuse") }</li>
                                <li>{ t("ip-whitelisting-pool-1-term-benefits-scaling") }</li>
                            </ul>

                            <p>{ t("ip-whitelisting-pool-1-term-whitelisting-guide") }</p>

                            <h2>{ t("ip-skipping-pool-1-term-ip-skipping") }</h2>
                            <p>{ t("ip-skipping-pool-1-term-ip-skipping-description") }</p>

                            <p>{ t("ip-skipping-pool-1-term-ip-skipping-activation") }</p>

                            <h2>{ t("allowances-pool-1-term-allowances") }</h2>
                            <p>{ t("allowances-pool-1-term-allowances-description") }</p>

                            <ul>
                                <li>{ t("allowances-pool-1-term-restrictions-section") }</li>
                                <li>{ t("allowances-pool-1-term-permissions-section") }</li>
                            </ul>

                            <p>{ t("allowances-pool-1-term-allowances-activation") }</p>

                            <p>{ t("allowances-pool-1-term-allowances-guide") }</p>

                            <h2>{ t("protocols-pool-1-term-protocols") }</h2>
                            <p>{ t("protocols-pool-1-term-protocols-description") }</p>

                            <p>{ t("protocols-pool-1-term-residential-proxies") }</p>

                            <h3>{ t("protocols-pool-1-term-http-https") }</h3>
                            <p>{ t("protocols-pool-1-term-http-https-description") }</p>

                            <h3>{ t("protocols-pool-1-term-socks5") }</h3>
                            <p>{ t("protocols-pool-1-term-socks5-description") }</p>

                            <p>{ t("protocols-pool-1-term-socks5-usage") }</p>

                            <h2>{ t("making-requests-pool-1-term-making-requests") }</h2>
                            <p>{ t("making-requests-pool-1-term-making-requests-description") }</p>

                            <p>{ t("making-requests-pool-1-term-making-requests-note") }</p>

                            <h3>{ t("making-requests-pool-1-term-http-https") }</h3>
                            <h4>{ t("making-requests-pool-1-term-curl") }</h4>
                            <pre><code>curl -v -x geo.psbproxy.io:12321:username123:password321_country-us_state-california -L https://ipv4.icanhazip.com</code></pre>

                            <h3>{ t("making-requests-pool-1-term-socks5") }</h3>
                            <h4>{ t("making-requests-pool-1-term-curl") }</h4>
                            <pre><code>curl -v --socks5 geo.psbproxy.io:32325 --proxy-user username123:password321_country-us_state-cal</code></pre>
                        </div>
                        <div className={style['support-article__footer']}>
                            <div className={style['container']}></div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
