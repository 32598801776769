import React, { useState } from 'react';
import './LandingHeader.css';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher';

const LandingHeader = () => {
    const { t } = useTranslation();
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

    const toggleMobileMenu = () => {
        const body = document.body;
        setIsMobileMenuOpened((prevState) => {
            const newState = !prevState;
            if (newState) {
                body.classList.add('no-scroll');
            } else {
                body.classList.remove('no-scroll');
            }
            return newState;
        });
    };
    

    return (
        <header className="landing-header">
            <div className="container">
                <div className="landing-header__left">
                    <Link className="landing-header__logo" to={'/'}>
                        <img src='/logo.svg' alt="logo"></img>
                    </Link>
                    <nav className='landing-header-nav'>
                        <Link className='landing-header-nav__item' to={"/"}>{t('name-home-page')}</Link>
                        <Link className='landing-header-nav__item' to={"/prices"}>{t('name-prices-page')}</Link>
                        <Link className='landing-header-nav__item' to={"/countries"}>{t('name-countries-page')}</Link>
                        <Link className='landing-header-nav__item' to={"/contacts"}>{t('name-contacts-page')}</Link>
                    </nav>
                </div>
                <div className="landing-header__right">
                    <LanguageSwitcher />
                    <Link className='primary-btn' to={"/login"}>
                        <span>{t('button-personal-cabinet')}</span>
                        <img src='/horizontal-arrow.svg' alt='arrow'></img>
                    </Link>
                    <div className={`burger-btn ${isMobileMenuOpened ? "opened" : ''}`} onClick={toggleMobileMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div className={`m-menu ${isMobileMenuOpened ? "opened" : ''}`}>
                <div className='container'>
                    <div className='m-menu__header' onClick={toggleMobileMenu}>
                        <img className='m-menu__arrow' src='/icons8-arrow-50.png' alt='arrow'></img>
                        <h3 className='m-menu__title'>PSB-Proxy</h3>
                    </div>
                    <div className='m-menu__content'>
                        <nav className='m-nav'>
                            <Link className='m-nav__item' to={"/"} onClick={toggleMobileMenu}>
                                <span>{t('name-home-page')}</span>
                            </Link>
                            <Link className='m-nav__item' to={"/prices"} onClick={toggleMobileMenu}>
                                <span>{t('name-prices-page')}</span>
                            </Link>
                            <Link className='m-nav__item' to={"/countries"} onClick={toggleMobileMenu}>
                                <span>{t('name-countries-page')}</span>
                            </Link>
                            <Link className='m-nav__item' to={"/privacy-policy"} onClick={toggleMobileMenu}>
                                <span>{t("name-privacy-page")}</span>
                            </Link>
                            <Link className='m-nav__item' to={'/terms-and-conditions'} onClick={toggleMobileMenu}>
                                <span>{t("name-conditions-page")}</span>
                            </Link>
                            <Link className='m-nav__item' to={'/contacts'} onClick={toggleMobileMenu}>
                                <span>{t("name-contacts-page")}</span>
                            </Link>
                            <LanguageSwitcher />
                        </nav>
                    </div>
                    <div className='m-menu__footer'>
                        <Link className='primary-btn' to={"/login"} onClick={toggleMobileMenu}>
                            <span>{t('button-personal-cabinet')}</span>
                            <img src='/horizontal-arrow.svg' alt='arrow'></img>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default LandingHeader;
