import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../store/userSlice';
import disableNewsletter from "../../api/users/disableNewsletter";
import processErrors from '../../utils/processErrors';
import { toast } from 'react-toastify';

export const NewsletterDisablePage = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleNewsletterDisable = async (event) => {
        try {
            event.preventDefault();

            toast.info(t('notification-request-await'));

            const response = await disableNewsletter(user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            toast.success(t('notification-success'));
            dispatch(fetchUserData(user.token));
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    return (
        <>
            <Helmet>
                <title>{t("page-title-account")}</title>
            </Helmet>
            <div className="newsletter-disable-page">
                <h1>{t('name-unsubscribe-newsletter-title')}</h1>

                {user?.data?.newsletter ? (
                    <div>
                        <p>{t('name-newsletter-active-info')}</p>
                        <button onClick={handleNewsletterDisable} className="btn btn-danger">
                            {t('button-unsubscribe')}
                        </button>
                    </div>
                ) : (
                    <p>{t('name-newsletter-inactive-info')}</p>
                )}
            </div>
        </>
    );
};
