const registerUser = async (email, username, password) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/user/register`;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, username, password })
        });

        return response;
    } catch (error) {
        console.error('Error:', error);
    }
};

export default registerUser;
