import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const TermsAndConditionsPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("page-title-terms-and-conditions")}</title>
                <meta name="description" content={t("page-description-terms-and-conditions")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-terms-and-conditions")} />
                <meta property="og:description" content={t("page-description-terms-and-conditions")} />
            </Helmet>
            <div className="info-page">
                <div className="info-container">
                    <h1 className="info-title">{t('terms.title')}</h1>
                    <p className="info-intro">{t('terms.intro')}</p>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section1.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section1.item1')}</li>
                            <li>{t('terms.section1.item2')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section2.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section2.item1')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section3.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section3.item1')}</li>
                            <li>{t('terms.section3.item2')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section4.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section4.item1')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section5.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section5.item1')}</li>
                            <li>{t('terms.section5.item2')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section6.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section6.item1')}</li>
                            <li>{t('terms.section6.item2')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section7.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section7.item1')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section8.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section8.item1')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section9.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section9.item1')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section10.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section10.item1')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section11.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section11.item1')}</li>
                        </ul>
                    </div>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('terms.section12.title')}</h2>
                        <ul className="info-list">
                            <li>{t('terms.section12.item1')}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
