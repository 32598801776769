import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const PrivacyPolicyPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("page-title-privacy-policy")}</title>
                <meta name="description" content={t("page-description-privacy-policy")} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content={t("page-title-privacy-policy")} />
                <meta property="og:description" content={t("page-description-privacy-policy")} />
            </Helmet>
            <div className="info-page">
                <div className="info-container">
                    <h1 className="info-title">{t('policy.title')}</h1>

                    <div className="info-section">
                        <h2 className="info-section-title">{t('policy.section1.title')}</h2>
                        <ul className="info-list">
                            <li>{t('policy.section1.item1')}</li>
                            <li>{t('policy.section1.item2')}</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="info-section-title">{t('policy.section2.title')}</h2>
                        <ul className="info-list">
                            <li>{t('policy.section2.item1')}</li>
                            <li>{t('policy.section2.item2')}</li>
                            <li>{t('policy.section2.item3')}</li>
                            <li>{t('policy.section2.item4')}</li>
                            <li>{t('policy.section2.item5')}</li>
                            <li>{t('policy.section2.item6')}</li>
                            <li>{t('policy.section2.item7')}</li>
                            <li>{t('policy.section2.item8')}</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="info-section-title">{t('policy.section3.title')}</h2>
                        <ul className="info-list">
                            <li>{t('policy.section3.item1')}</li>
                            <li>{t('policy.section3.item2')}</li>
                            <li>{t('policy.section3.item3')}</li>
                            <li>{t('policy.section3.item4')}</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="info-section-title">{t('policy.section4.title')}</h2>
                        <ul className="info-list">
                            <li>{t('policy.section4.item1')}</li>
                            <li>{t('policy.section4.item2')}</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="info-section-title">{t('policy.section5.title')}</h2>
                        <ul className="info-list">
                            <li>{t('policy.section5.item1')}</li>
                            <li>{t('policy.section5.item2')}</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="info-section-title">{t('policy.section6.title')}</h2>
                        <ul className="info-list">
                            <li>{t('policy.section6.item1')}</li>
                            <li>{t('policy.section6.item2')}</li>
                            <li>{t('policy.section6.item3')}</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="info-section-title">{t('policy.section7.title')}</h2>
                        <ul className="info-list">
                            <li>{t('policy.section7.description')}</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="info-section-title">{t('policy.conclusion.title')}</h2>
                        <ul className="info-list">
                            <li>{t('policy.conclusion.item1')}</li>
                            <li>{t('policy.conclusion.item2')}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
